// charge.model.ts

import { IStreetBuilding, StreetBuilding } from "./street-building.model";

export interface ICharge {
  chargeId: string;
  neighborhoodId: string;
  charge: string; // Representa el monto como string para manejar decimales
  uidCreatedBy?: string; // Opcional
  uidUpdatedBy?: string; // Opcional
  createdAt: string;
  updatedAt: string;
  streetsBuildings: IStreetBuilding[];
  overdue: number;
  pending: number;
  done: number;
}

export class Charge {
  chargeId: string;
  neighborhoodId: string;
  charge: number; // Convertido a número para facilidad en cálculos
  uidCreatedBy?: string; // Opcional
  uidUpdatedBy?: string; // Opcional
  createdAt: Date;
  updatedAt: Date;
  streetsBuildings: StreetBuilding[];
  overdue: number;
  pending: number;
  done: number;

  constructor(data: ICharge) {
    this.chargeId = data.chargeId;
    this.neighborhoodId = data.neighborhoodId;
    this.charge = parseFloat(data.charge); // Convertir string a número
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.streetsBuildings = data.streetsBuildings
      ? data.streetsBuildings.map(
          (streetBuilding) => new StreetBuilding(streetBuilding)
        )
      : [];
      this.overdue = data.overdue;
      this.pending = data.pending;
      this.done = data.done;
  }
}
