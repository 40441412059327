import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmergencyNumberService } from '../../../services/emergency-number.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerService } from '../../../services/spinner.service';
import { EmergencyNumber } from '../../../models/emergency-number.model';
import { EmergencyNumberModalComponent } from '../../../components/emergency-number-modal/emergency-number-modal.component';

@Component({
  selector: 'app-emergency-numbers-page',
  templateUrl: './emergency-numbers-page.component.html',
  styleUrl: './emergency-numbers-page.component.scss'
})
export class EmergencyNumbersPageComponent {

  public neighborhoodId?: string;
  
  constructor(
    private activatedRoute: ActivatedRoute,
    public emergencyNumberService: EmergencyNumberService,
    private modalService: NgbModal,
    public spinnerService: SpinnerService
  ) {
    this.activatedRoute.params.subscribe(
      async params => {
        this.neighborhoodId = params['neighborhoodId'];
        await this.emergencyNumberService.getAll(
          this.neighborhoodId!
        );
      }
    );
  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝
  */
  async delete(
    emergencyNumber: EmergencyNumber
  ) {
    const flag = confirm(`¿Está seguro que desea borrar el número de emergenci "${emergencyNumber.phoneNumber}"?`);

    if (flag) {
      await this.emergencyNumberService.delete(
        emergencyNumber
      );
    }
  }

  /*
   ██████╗ ██████╗ ███████╗███╗   ██╗
  ██╔═══██╗██╔══██╗██╔════╝████╗  ██║
  ██║   ██║██████╔╝█████╗  ██╔██╗ ██║
  ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║
  ╚██████╔╝██║     ███████╗██║ ╚████║
   ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝

  */
  open(
    emergencyNumber?: EmergencyNumber
  ) {
    const modalRef = this.modalService.open(
      EmergencyNumberModalComponent
    );
    
    modalRef.componentInstance.neighborhoodId = this.neighborhoodId;
    modalRef.componentInstance.emergencyNumber = emergencyNumber;
  }

  async update(
    emergencyNumber: EmergencyNumber
  ) {
    const flag = await this.emergencyNumberService.update(
      emergencyNumber
    );

    if(!flag) {
      emergencyNumber.status = !emergencyNumber.status;
    }
  }

}
