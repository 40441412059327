<div class="container-fluid py-3">
  <div class="row">

    @for(neighborhood of neighborhoodService.neighborhoods; track $index) {

      <app-neighborhood-item
        [neighborhood]="neighborhood"
        class="col-12">
      </app-neighborhood-item>

    }

  </div>
</div>