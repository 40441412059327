// bank-account.model.ts

import { BankAccountType, IBankAccountType } from "./bank-account-type.model";

export interface IBankAccount {
  bankAccountId: string;
  bankAccountTypeId: string;
  neighborhoodId: string;
  bankName: string;
  fullName: string;
  accountNumber: string;
  uidCreatedBy?: string; // Opcional
  uidUpdatedBy?: string; // Opcional
  createdAt: string;
  updatedAt: string;
  bankAccountType?: IBankAccountType;
}

export class BankAccount {
  bankAccountId: string;
  bankAccountTypeId: string;
  neighborhoodId: string;
  bankName: string;
  fullName: string;
  accountNumber: string;
  uidCreatedBy?: string; // Opcional
  uidUpdatedBy?: string; // Opcional
  createdAt: Date;
  updatedAt: Date;
  bankAccountType?: BankAccountType;

  constructor(data: IBankAccount) {
    this.bankAccountId = data.bankAccountId;
    this.bankAccountTypeId = data.bankAccountTypeId;
    this.neighborhoodId = data.neighborhoodId;
    this.bankName = data.bankName;
    this.fullName = data.fullName;
    this.accountNumber = data.accountNumber;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.bankAccountType = data.bankAccountType 
      ? new BankAccountType(data.bankAccountType) 
      : undefined;
  }
}
