import { FileMedia, IFileMedia } from "./file-media.model";
import { HouseFlat, IHouseFlat } from "./house-flat.model";
import { IService, Service } from "./service.model";
import { IVisitType, VisitType } from "./visit-type.model";

export interface IVisit {
  visitId: string;
  visitTypeId: string;
  superVisitId?: string; // Usando ? para indicar que es opcional
  houseFlatId: string;
  contactId?: string; // Usando ? para indicar que es opcional
  names: string;
  paternalLastName: string;
  maternalLastName?: string; // Usando ? para indicar que es opcional
  plates: string;
  brand: string;
  model: string;
  color: string;
  status: boolean;
  approved?: boolean;
  token: string;
  arrivedAt?: string; // Usando ? para indicar que es opcional
  departedAt?: string; // Usando ? para indicar que es opcional
  uidCreatedBy?: string;
  uidUpdatedBy?: string;
  createdAt: string;
  updatedAt: string;
  visitType?: IVisitType;
  houseFlat?: IHouseFlat;
  proofs?: IFileMedia[];
  service?: IService;
}

export class Visit {
  visitId: string;
  visitTypeId: string;
  superVisitId?: string; // Usando ? para indicar que es opcional
  houseFlatId: string;
  contactId?: string; // Usando ? para indicar que es opcional
  names: string;
  paternalLastName: string;
  maternalLastName?: string; // Usando ? para indicar que es opcional
  plates: string;
  brand: string;
  model: string;
  color: string;
  status: boolean;
  approved?: boolean;
  token: string;
  arrivedAt?: Date; // Usando ? para indicar que es opcional
  departedAt?: Date; // Usando ? para indicar que es opcional
  uidCreatedBy?: string;
  uidUpdatedBy?: string;
  createdAt: Date;
  updatedAt: Date;
  visitType?: VisitType;
  houseFlat?: HouseFlat;
  proofs?: FileMedia[];
  service?: Service;

  constructor(data: IVisit) {
    this.visitId = data.visitId;
    this.visitTypeId = data.visitTypeId;
    this.superVisitId = data.superVisitId;
    this.houseFlatId = data.houseFlatId;
    this.contactId = data.contactId;
    this.names = data.names;
    this.paternalLastName = data.paternalLastName;
    this.maternalLastName = data.maternalLastName;
    this.plates = data.plates;
    this.brand = data.brand;
    this.model = data.model;
    this.color = data.color;
    this.status = data.status;
    this.approved = data.approved;
    this.token = data.token;
    this.arrivedAt = data.arrivedAt ? new Date(data.arrivedAt) : undefined;
    this.departedAt = data.departedAt ? new Date(data.departedAt) : undefined;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.visitType = data.visitType 
      ? new VisitType(data.visitType) 
      : undefined
    this.houseFlat = data.houseFlat
      ? new HouseFlat(data.houseFlat)
      : undefined;
    this.proofs = data.proofs
      ? data.proofs.map(proof => new FileMedia(proof))
      : [];
    this.service = data.service
      ? new Service(data.service)
      : undefined;
  }

  get fullName(): string {

    return `${this.names} ${this.paternalLastName} ${this.maternalLastName}`;
  }
}
