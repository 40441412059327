import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnnouncementService } from '../../../services/announcement.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerService } from '../../../services/spinner.service';
import { Announcement } from '../../../models/announcement.model';
import { CreateAnnouncementModalComponent } from '../../../components/create-announcement-modal/create-announcement-modal.component';
import { AnnouncementModalComponent } from '../../../components/announcement-modal/announcement-modal.component';

@Component({
  selector: 'app-announcements-page',
  templateUrl: './announcements-page.component.html',
  styleUrl: './announcements-page.component.scss'
})
export class AnnouncementsPageComponent {

  public neighborhoodId?: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    public announcementService: AnnouncementService,
    private modalService: NgbModal,
    public spinnerService: SpinnerService
  ) {
    this.activatedRoute.params.subscribe(
      async params => {
        this.neighborhoodId = params['neighborhoodId'];
        await this.announcementService.getAll(this.neighborhoodId!);
      }
    );
  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝
  */
  async delete(
    announcement: Announcement
  ) {
    const flag = confirm(`¿Está seguro que desea borrar el anuncio "${announcement.title}"?`);

    if (flag) {
      await this.announcementService.delete(announcement);
    }
  }

  /*
   ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
   ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  create() {
    const modalRef = this.modalService.open(
      CreateAnnouncementModalComponent
    );
    
    modalRef.componentInstance.neighborhoodId = this.neighborhoodId;
  }

  view(announcement: Announcement) {
    const modalRef = this.modalService.open(
      AnnouncementModalComponent
    );
    
    modalRef.componentInstance.announcement = announcement;
    modalRef.componentInstance.neighborhoodId = this.neighborhoodId;
  }

}
