import { Injectable } from '@angular/core';
import { INotificationType, NotificationType } from '../models/notification-type.model';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from './spinner.service';
import { ToastService } from './toast.service';
import { environment } from '../../environments/environment';
import { IServerResponse, ServerResponse } from '../models/server-response.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationTypeService {

  public loading: boolean = false;
  public notificationTypes: NotificationType[] = [];

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    public spinnerService: SpinnerService,
    public toastService: ToastService
  ) { 
    this.getAll();
  }

  /*
  ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
  ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async create(
    notificationType: INotificationType
  ): Promise<boolean> {
    let flag = false;
    const urlApi = `${environment.urlApi}/notification-types`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Registrando tipo de notificación...';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.post<IServerResponse>(
            urlApi,
            JSON.stringify(notificationType),
            {
              headers: {
                'content-type': 'application/json',
                'Authorization': token.toString()
              }
            }
          ).subscribe({
            next: (response: IServerResponse) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show({
                  header: 'Error de servidor',
                  body: `${error}`,
                  classname: 'bg-danger text-white'
                });
              }
            },
          });
        }
      );

      if(response.statusCode === 201) {
        flag = true;
        this.toastService.show({
          body: `${response.message}`,
          classname: 'bg-success text-white'
        });
        this.getAll();
      } else {
        this.toastService.show({
          header: 'Error de servidor',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }
    } catch(error) {
      this.toastService.show({
        header: 'Error',
        body: `${error}`,
        classname: 'bg-danger text-white'
      });
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return flag;
  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  async delete(
    notificationType: NotificationType
  ): Promise<boolean> {
    let flag = false;
    const urlApi = `${environment.urlApi}/notification-types/${notificationType.notificationTypeId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Eliminando tipo de notificación...';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.delete<IServerResponse>(
            urlApi,
            {
              headers: { 'Authorization': token.toString() }
            }
          ).subscribe({
            next: (response: IServerResponse) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show({
                  header: 'Error de servidor',
                  body: `${error}`,
                  classname: 'bg-danger text-white'
                });
              }
            },
          });
        }
      );

      if(response.statusCode === 200) {
        flag = true;
        this.toastService.show({
          body: `${response.message}`,
          classname: 'bg-success text-white'
        });
        this.getAll();
      } else {
        this.toastService.show({
          header: 'Error de servidor',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }
    } catch(error) {
      this.toastService.show({
        header: 'Error',
        body: `${error}`,
        classname: 'bg-danger text-white'
      });
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';
    return flag;
  }

  /*
  ██████╗ ███████╗████████╗ █████╗ ██╗     ██╗
  ██╔════╝ ██╔════╝╚══██╔══╝██╔══██╗██║     ██║
  ██║  ███╗█████╗     ██║   ███████║██║     ██║
  ██║   ██║██╔══╝     ██║   ██╔══██║██║     ██║
  ╚██████╔╝███████╗   ██║   ██║  ██║███████╗███████╗
  ╚═════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚══════╝╚══════╝

  */
  async getAll() {
    this.loading = true;
    const urlApi = `${environment.urlApi}/notification-types`;

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse<{ notificationTypes: INotificationType[] }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ notificationTypes: INotificationType[] }>>(
            urlApi,
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (response) => resolve(
              new ServerResponse(response)
            ),
            error: (error) => reject(error),
          });
        }
      );

      if (response.statusCode === 200) {
        this.notificationTypes = response.data!.notificationTypes.map(
          type => new NotificationType(type)
        );
      }
    } catch(error) {
      this.toastService.show({
        header: 'Error de servidor',
        body: `${error}`,
        classname: 'bg-danger text-white'
      });
    }

    this.loading = false;
  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
  ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async update(
    notificationType: INotificationType
  ): Promise<boolean> {
    let flag = false;
    const urlApi = `${environment.urlApi}/notification-types/${notificationType.notificationTypeId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Actualizando tipo de notificación...';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.put<IServerResponse>(
            urlApi,
            JSON.stringify(notificationType),
            {
              headers: {
                'content-type': 'application/json',
                'Authorization': token.toString()
              }
            }
          ).subscribe({
            next: (response: IServerResponse) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show({
                  header: 'Error de servidor',
                  body: `${error}`,
                  classname: 'bg-danger text-white'
                });
              }
            },
          });
        }
      );

      if (response.statusCode === 200) {
        flag = true;

        this.toastService.show({
          body: `${response.message}`,
          classname: 'bg-success text-white'
        });

        this.getAll();
      } else {
        this.toastService.show({
          header: 'Error de servidor',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }
    } catch (error) {
      this.toastService.show({
        header: 'Error',
        body: `${error}`,
        classname: 'bg-danger text-white'
      });
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return flag;
  }

}
