import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from './spinner.service';
import { StreetBuildingService } from './street-building.service';
import { ToastService } from './toast.service';
import { Resident } from '../models/resident.model';
import { environment } from '../../environments/environment';
import { IServerResponse, ServerResponse } from '../models/server-response.model';
import { AppUser, IAppUser } from '../models/app-user.model';

@Injectable({
  providedIn: 'root'
})
export class ResidentService {

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    public spinnerService: SpinnerService,
    public streetBuildingService: StreetBuildingService,
    public toastService: ToastService
  ) { 

  }

  /*
   ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
   ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async create(
    neighborhoodId: string,
    streetBuildingId: string,
    houseFlatId: string,
  ): Promise<AppUser | undefined> {
    
    let appUser = undefined;

    const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/streets-buildings/${streetBuildingId}/houses-flats/${houseFlatId}/user`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Creando residente';

    try {

      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse<{user: IAppUser}>>(
        (resolve, reject) => {
          this.http.post<IServerResponse<{user: IAppUser}>>(
            urlApi,
            {},
            {
              headers: {
                'Authorization': token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse<{user: IAppUser}>
              ) => resolve(
                new ServerResponse<{user: IAppUser}>(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show(
                  {
                    header: 'Error de servidor',
                    body: `${error}`,
                    classname: 'bg-danger text-white'
                  }
                );
              }
            },
          });
        }
      );

      console.log(response);

      if(response.statusCode === 201) {
        appUser = new AppUser(response.data!.user);

        this.toastService.show(
          {
            body: `${response.message}`,
            classname: 'bg-success text-white'
          }
        );

        this.streetBuildingService.getAll(
          neighborhoodId
        );

      } else {

        this.toastService.show(
          {
            header: 'Error de servidor',
            body: `${response.message}`,
            classname: 'bg-danger text-white'
          }
        );

      }

    } catch(error) {
      console.log(error);

      this.toastService.show(
        {
          header: 'Error',
          body: `${error}`,
          classname: 'bg-danger text-white'
        }
      );
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return appUser;

  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  async delete(
    neighborhoodId: string,
    streetBuildingId: string,
    resident: Resident
  ): Promise<boolean> {

    let flag = false;

    const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/streets-buildings/${streetBuildingId}/houses-flats/${resident.houseFlatId}/residents/${resident.uidResident}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Eliminando residente';

    try {

      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.delete<IServerResponse>(
            urlApi,
            {
              headers: {
                'Authorization': token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse
              ) => resolve(
                new ServerResponse(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show(
                  {
                    header: 'Error de servidor',
                    body: `${error}`,
                    classname: 'bg-danger text-white'
                  }
                );
              }
            },
          });
        }
      );

      console.log(response);

      if(response.statusCode === 200) {
        flag = true;

        this.toastService.show(
          {
            body: `${response.message}`,
            classname: 'bg-success text-white'
          }
        );

        this.streetBuildingService.getAll(
          neighborhoodId
        );

      } else {

        this.toastService.show(
          {
            header: 'Error de servidor',
            body: `${response.message}`,
            classname: 'bg-danger text-white'
          }
        );

      }

    } catch(error) {
      console.log(error);

      this.toastService.show(
        {
          header: 'Error',
          body: `${error}`,
          classname: 'bg-danger text-white'
        }
      );
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';
  
    return flag;

  }

  /*
  ██████╗ ███████╗███████╗████████╗ ██████╗ ██████╗ ███████╗██████╗  █████╗ ███████╗███████╗██╗    ██╗ ██████╗ ██████╗ ██████╗
  ██╔══██╗██╔════╝██╔════╝╚══██╔══╝██╔═══██╗██╔══██╗██╔════╝██╔══██╗██╔══██╗██╔════╝██╔════╝██║    ██║██╔═══██╗██╔══██╗██╔══██╗
  ██████╔╝█████╗  ███████╗   ██║   ██║   ██║██████╔╝█████╗  ██████╔╝███████║███████╗███████╗██║ █╗ ██║██║   ██║██████╔╝██║  ██║
  ██╔══██╗██╔══╝  ╚════██║   ██║   ██║   ██║██╔══██╗██╔══╝  ██╔═══╝ ██╔══██║╚════██║╚════██║██║███╗██║██║   ██║██╔══██╗██║  ██║
  ██║  ██║███████╗███████║   ██║   ╚██████╔╝██║  ██║███████╗██║     ██║  ██║███████║███████║╚███╔███╔╝╚██████╔╝██║  ██║██████╔╝
  ╚═╝  ╚═╝╚══════╝╚══════╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚══════╝╚═╝     ╚═╝  ╚═╝╚══════╝╚══════╝ ╚══╝╚══╝  ╚═════╝ ╚═╝  ╚═╝╚═════╝

  */
  async restorePassword(
    neighborhoodId: string,
    streetBuildingId: string,
    resident: Resident
  ): Promise<AppUser | undefined> {
    
    let appUser = undefined;

    const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/streets-buildings/${streetBuildingId}/houses-flats/${resident.houseFlatId}/residents/${resident.uidResident}/restore-password`;

    this.spinnerService.loading = true;
    this.spinnerService.message = resident.resident!.username != ""
      ? 'Restaurando contraseña del residente'
      : 'Enviando liga para restablecer contraseña del residente';

    try {

      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse<{user: IAppUser}>>(
        (resolve, reject) => {
          this.http.post<IServerResponse<{user: IAppUser}>>(
            urlApi,
            {},
            {
              headers: {
                'Authorization': token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse<{user: IAppUser}>
              ) => resolve(
                new ServerResponse<{user: IAppUser}>(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show(
                  {
                    header: 'Error de servidor',
                    body: `${error}`,
                    classname: 'bg-danger text-white'
                  }
                );
              }
            },
          });
        }
      );

      console.log(response);

      if(response.statusCode === 200) {
        appUser = new AppUser(response.data!.user);

        this.toastService.show(
          {
            body: `${response.message}`,
            classname: 'bg-success text-white'
          }
        );

        this.streetBuildingService.getAll(
          neighborhoodId
        );

      } else {

        this.toastService.show(
          {
            header: 'Error de servidor',
            body: `${response.message}`,
            classname: 'bg-danger text-white'
          }
        );

      }

    } catch(error) {
      console.log(error);

      this.toastService.show(
        {
          header: 'Error',
          body: `${error}`,
          classname: 'bg-danger text-white'
        }
      );
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return appUser;

  }
}
