import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'dateToMoment'
})
export class DateToMomentPipe implements PipeTransform {

  transform(value: Date | string | undefined, ...args: unknown[]): unknown {

    return value 
      ? moment(value).add(args[1] ?? 0, 'hours').format(args[0] as string)
      : '';
  }

}
