<div class="row">
  <a 
    class="col-12 bg-white py-3 px-3 rounded mb-4 text-decoration-none text-black"
    [routerLink]="['/neighborhoods', neighborhood?.neighborhoodId]">
    <div class="row">

      <div class="col-12 mb-3">
        <h4><b>{{ neighborhood?.name }}</b></h4>
        <!-- <small class="text-secondary">{{ neighborhood?.test ? 'Prueba' : 'Producción' }}</small> -->
      </div>

      <div class="col-12">
        <div class="row">

          <div class="col-6 col-md-4 col-lg-4 col-xl-2">
            <div class="row">

              <div class="col-12 mb-1">
                <b><small class="text-secondary">Requiere de pruebas</small></b>
              </div>
              <div class="col-12">
                @if(neighborhood?.proofsRequired) {
                  <i class="material-icons text-success">
                    done
                  </i>
                } @else {
                  <i class="material-icons text-danger">
                    close
                  </i>
                }
              </div>

            </div>
          </div>

          <div class="col-6 col-md-4 col-lg-4 col-xl-2">
            <div class="row">

              <div class="col-12 mb-1">
                <b><small class="text-secondary">Requiere placas</small></b>
              </div>
              <div class="col-12">
                @if(neighborhood?.platesRequired) {
                  <i class="material-icons text-success">
                    done
                  </i>
                } @else {
                  <i class="material-icons text-danger">
                    close
                  </i>
                }
              </div>
              
            </div>
          </div>

          <div class="col-6 col-md-4 col-lg-4 col-xl-2">
            <div class="row">

              <div class="col-12 mb-1">
                <b><small class="text-secondary">Requiere de marca</small></b>
              </div>
              <div class="col-12">
                @if(neighborhood?.brandRequired) {
                  <i class="material-icons text-success">
                    done
                  </i>
                } @else {
                  <i class="material-icons text-danger">
                    close
                  </i>
                }
              </div>
              
            </div>
          </div>

          <div class="col-6 col-md-4 col-lg-4 col-xl-2">
            <div class="row">

              <div class="col-12 mb-1">
                <b><small class="text-secondary">Requiere de modelo</small></b>
              </div>
              <div class="col-12">
                @if(neighborhood?.modelRequired) {
                  <i class="material-icons text-success">
                    done
                  </i>
                } @else {
                  <i class="material-icons text-danger">
                    close
                  </i>
                }
              </div>
              
            </div>
          </div>

          <div class="col-6 col-md-4 col-lg-4 col-xl-2">
            <div class="row">

              <div class="col-12 mb-1">
                <b><small class="text-secondary">Requiere de color</small></b>
              </div>
              <div class="col-12">
                @if(neighborhood?.colorRequired) {
                  <i class="material-icons text-success">
                    done
                  </i>
                } @else {
                  <i class="material-icons text-danger">
                    close
                  </i>
                }
              </div>
              
            </div>
          </div>

          <div class="col-6 col-md-4 col-lg-4 col-xl-2 mb-3">
            <div class="row">

              <div class="col-12">
                <b><small class="text-secondary">Zona horario</small></b>
              </div>
              <div class="col-12">
                <b>{{ neighborhood?.timeZone}}</b>
              </div>
              
            </div>
          </div>

          <div class="col-6 col-md-4 col-lg-4 col-xl-2">
            <div class="row">

              <div class="col-12">
                <b><small class="text-secondary">Número de licencias</small></b>
              </div>
              <div class="col-12">
                <b>{{ neighborhood?.numberProperties}}</b>
              </div>
              
            </div>
          </div>

          <div class="col-6 col-md-4 col-lg-4 col-xl-2">
            <div class="row">

              <div class="col-12">
                <b><small class="text-secondary">Usuarios x propiedad</small></b>
              </div>
              <div class="col-12">
                <b>{{ neighborhood?.accountsPerProperty}}</b>
              </div>
              
            </div>
          </div>

          <div class="col-6 col-md-4 col-lg-4 col-xl-2">
            <div class="row">

              <div class="col-12">
                <b><small class="text-secondary">Dispositivos x usuario</small></b>
              </div>
              <div class="col-12">
                <b>{{ neighborhood?.devicesPerProperty}}</b>
              </div>
              
            </div>
          </div>

          <div class="col-6 col-md-4 col-lg-4 col-xl-2">
            <div class="row">

              <div class="col-12">
                <b><small class="text-secondary">Mantenimiento</small></b>
              </div>
              <div class="col-12">
                @if(neighborhood?.maintenanceFee == 0) {
                  <b>No registrado</b>
                } @else {
                  <b>{{ neighborhood?.maintenanceFee}}</b>
                }
              </div>
              
            </div>
          </div>

          <div class="col-6 col-md-4 col-lg-4 col-xl-2">
            <div class="row">

              <div class="col-12">
                <b><small class="text-secondary">Día max. de pago</small></b>
              </div>
              <div class="col-12">
                @if(neighborhood?.payday == 0) {
                  <b>No registrado</b>
                } @else {
                  <b>{{ neighborhood?.payday}} de cada mes</b>
                }
              </div>
              
            </div>
          </div>

        </div>
      </div>

    </div>
  </a>
</div>