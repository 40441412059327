// NotificationType.model.ts

import { FileMedia, IFileMedia } from "./file-media.model";

export interface INotificationType {
  notificationTypeId: string;
  code: string;
  notificationType: string;
  fileId: string;
  uidCreatedBy?: string; // Usando ? para indicar que es opcional
  uidUpdatedBy?: string; // Usando ? para indicar que es opcional
  createdAt: string;
  updatedAt: string;
  image?: IFileMedia;
}

export class NotificationType {
  notificationTypeId: string;
  code: string;
  notificationType: string;
  fileId: string;
  uidCreatedBy?: string; // Usando ? para indicar que es opcional
  uidUpdatedBy?: string; // Usando ? para indicar que es opcional
  createdAt: Date;
  updatedAt: Date;
  image?: FileMedia;

  constructor(data: INotificationType) {
    this.notificationTypeId = data.notificationTypeId;
    this.code = data.code;
    this.notificationType = data.notificationType;
    this.fileId = data.fileId;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.image = data.image 
      ? new FileMedia(data.image) 
      : undefined;
  }
}
