// Service.model.ts

import { FileMedia, IFileMedia } from "./file-media.model";

export interface IService {
  serviceId: string;
  neighborhoodId: string;
  service: string;
  fileId?: string; // Usando ? para indicar que es opcional
  status: boolean;
  uidCreatedBy?: string; // Usando ? para indicar que es opcional
  uidUpdatedBy?: string; // Usando ? para indicar que es opcional
  createdAt: string;
  updatedAt: string;
  image?: IFileMedia;
}

export class Service {
  serviceId: string;
  neighborhoodId: string;
  service: string;
  fileId?: string; // Usando ? para indicar que es opcional
  status: boolean;
  uidCreatedBy?: string; // Usando ? para indicar que es opcional
  uidUpdatedBy?: string; // Usando ? para indicar que es opcional
  createdAt: Date;
  updatedAt: Date;
  image?: FileMedia;

  constructor(data: IService) {
    this.serviceId = data.serviceId;
    this.neighborhoodId = data.neighborhoodId;
    this.service = data.service;
    this.fileId = data.fileId;
    this.status = data.status;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.image = data.image 
      ? new FileMedia(data.image) 
      : undefined;
  }
}
