<div class="modal-header">
  <h4 class="modal-title">
    @if (bankAccountType) {
      Editar módulo {{ bankAccountType.bankAccountType }} 
    }

    @if (!bankAccountType) {
      Nueva módulo
    }
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  @if (!loading) {
    <form
      id="formBankAccountType"
      [formGroup]="formBankAccountType"
      (ngSubmit)="onSubmit()">
      <div class="container-fluid">
        <div class="row">

          @for (key of utilService.objectKeys(dataForm); track key) {
            @if (
              dataForm[key].type != "select" && 
              dataForm[key].type != "switch" &&
              dataForm[key].type != "textarea" &&
              dataForm[key].type != "image"
            ) {
              <div class="col-12 form-group mb-4">
                <label [htmlFor]="key" class="form-label">
                  {{ dataForm[key].label }}
                </label>
                <input
                  [type]="dataForm[key].type"
                  class="form-control"
                  formControlName="{{ key }}"
                  [placeholder]="dataForm[key].placeholder ?? dataForm[key].label"
                  [ngClass]="{
                    'is-invalid': submitted && f[key].errors
                  }">
                  @if (submitted && f[key].errors && dataForm[key].required) {
                    <div class="invalid-feedback">
                      @if (f[key].errors!['required']) {
                        <div>{{ dataForm[key].requiredMessage }}</div>
                      }
                    </div>
                  }
              </div>
            }

          }

        </div>
      </div>
    </form>
  }
</div>
<div class="modal-footer">
  <div class="col-12 my-3">
    <button
      (click)="activeModal.close()"
      type="button"
      class="btn btn-outline-danger w-100">
      Cancelar
    </button>
  </div>
  <div class="col-12">
    <button
      type="submit"
      form="formBankAccountType"
      class="btn btn-primary w-100">
      {{ bankAccountType ? 'Actualizar' : 'Registrar' }}
    </button>
  </div>
</div>