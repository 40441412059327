import { Injectable } from '@angular/core';
import { BankAccount, IBankAccount } from '../models/bank-account.model';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from './spinner.service';
import { ToastService } from './toast.service';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { IServerResponse, ServerResponse } from '../models/server-response.model';

@Injectable({
  providedIn: 'root'
})
export class BankAccountService {

  public loading: boolean = false;
  public bankAccounts: BankAccount[] = [];

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private spinnerService: SpinnerService,
    private toastService: ToastService,
  ) {

  }

  /**
   * Obtener todos los bancos
   */
  async getAll(
    neighborhoodId: string
  ): Promise<void> {
    this.loading = true;
    const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/bank-accounts`;

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse<{ bankAccounts: IBankAccount[] }>>(
        (resolve, reject) => {
          this.http
            .get<IServerResponse<{ bankAccounts: IBankAccount[] }>>(urlApi, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: token.toString(),
              },
            })
            .subscribe({
              next: (response) =>
                resolve(new ServerResponse<{ bankAccounts: IBankAccount[] }>(response)),
              error: (error) => {
                if (error.error) {
                  resolve(new ServerResponse(error.error));
                } else {
                  reject(error);
                }
              },
            });
        }
      );

      if (response.statusCode === 200) {

        this.bankAccounts = response.data!.bankAccounts.map(
          (item) => new BankAccount(item)
        );
      } else {
        this.toastService.show({
          header: 'Error',
          body: response.message,
          classname: 'bg-danger text-white',
        });
      }
    } catch (error) {
      console.error(error);
      this.toastService.show({
        header: 'Error de servidor',
        body: `${error}`,
        classname: 'bg-danger text-white',
      });
    } finally {
      this.loading = false;
    }
  }

  /**
   * Crear un nuevo banco
   */
  async create(
    neighborhoodId: string,
    bankAccount: IBankAccount
  ): Promise<boolean> {
    let flag = false;
    const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/bank-accounts`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Creando cuenta bancaria...';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.post<IServerResponse>(
            urlApi,
            JSON.stringify(bankAccount),
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: token.toString(),
              },
            }
          ).subscribe({
            next: (response) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
              }
            },
          });
        }
      );

      if (response.statusCode === 201) {
        flag = true;

        this.getAll(
          neighborhoodId
        );

        this.toastService.show({
          body: response.message,
          classname: 'bg-success text-white',
        });
      } else {
        this.toastService.show({
          header: 'Error',
          body: response.message,
          classname: 'bg-danger text-white',
        });
      }
    } catch (error) {
      console.error(error);
      this.toastService.show({
        header: 'Error de servidor',
        body: `${error}`,
        classname: 'bg-danger text-white',
      });
    } finally {
      this.spinnerService.loading = false;
    }

    return flag;
  }

  /**
   * Actualizar un banco
   */
  async update(
    bankAccount: BankAccount
  ): Promise<boolean> {
    let flag = false;
    const urlApi = `${environment.urlApi}/neighborhoods/${bankAccount.neighborhoodId}/bank-accounts/${bankAccount.bankAccountId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Actualizando cuenta bancaria...';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.put<IServerResponse>(
            urlApi,
            JSON.stringify(bankAccount),
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: token.toString(),
              },
            }
          ).subscribe({
            next: (response) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
              }
            },
          });
        }
      );

      if (response.statusCode === 200) {
        flag = true;
        this.getAll(
          bankAccount.neighborhoodId
        );
        this.toastService.show({
          body: response.message,
          classname: 'bg-success text-white',
        });
      } else {
        this.toastService.show({
          header: 'Error',
          body: response.message,
          classname: 'bg-danger text-white',
        });
      }
    } catch (error) {
      console.error(error);
      this.toastService.show({
        header: 'Error de servidor',
        body: `${error}`,
        classname: 'bg-danger text-white',
      });
    } finally {
      this.spinnerService.loading = false;
    }

    return flag;
  }

  /**
   * Eliminar un banco
   */
  async delete(
    bankAccount: BankAccount
  ): Promise<boolean> {
    let flag = false;
    const urlApi = `${environment.urlApi}/neighborhoods/${bankAccount.neighborhoodId}/bank-accounts/${bankAccount.bankAccountId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Eliminando cuenta bancaria...';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.delete<IServerResponse>(
            urlApi,
            {
              headers: {
                Authorization: token.toString(),
              },
            }
          ).subscribe({
            next: (response) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
              }
            },
          });
        }
      );

      if (response.statusCode === 200) {
        flag = true;
        this.getAll(
          bankAccount.neighborhoodId
        );
        this.toastService.show({
          body: response.message,
          classname: 'bg-success text-white',
        });
      } else {
        this.toastService.show({
          header: 'Error',
          body: response.message,
          classname: 'bg-danger text-white',
        });
      }
    } catch (error) {
      console.error(error);
      this.toastService.show({
        header: 'Error de servidor',
        body: `${error}`,
        classname: 'bg-danger text-white',
      });
    } finally {
      this.spinnerService.loading = false;
    }

    return flag;
  }

  /**
   * Obtener una cuenta bancaria por ID
   */
  async get(
    neighborhoodId: string,
    id: string
  ): Promise<BankAccount | null> {
    const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/bank-accounts/${id}`;
    let bankAccount: BankAccount | null = null;

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse<{bankAccount: IBankAccount}>>(
        (resolve, reject) => {
          this.http
            .get<IServerResponse<{bankAccount: IBankAccount}>>(urlApi, {
              headers: {
                Authorization: token.toString(),
              },
            })
            .subscribe({
              next: (response) => resolve(new ServerResponse<{bankAccount: IBankAccount}>(response)),
              error: (error) => {
                if (error.error) {
                  resolve(new ServerResponse(error.error));
                } else {
                  reject(error);
                }
              },
            });
        }
      );

      if (response.statusCode === 200) {
        bankAccount = new BankAccount(response.data!.bankAccount);
      } else {
        this.toastService.show({
          header: 'Error',
          body: response.message,
          classname: 'bg-danger text-white',
        });
      }
    } catch (error) {
      console.error(error);
      this.toastService.show({
        header: 'Error de servidor',
        body: `${error}`,
        classname: 'bg-danger text-white',
      });
    }

    return bankAccount;
  }
}
