// StreetBuilding.model.ts

import { HouseFlat, IHouseFlat } from "./house-flat.model";

export interface IStreetBuilding {
  streetBuildingId: string;
  neighborhoodId: string;
  name: string;
  uidCreatedBy?: string; // Usando ? para indicar que es opcional
  uidUpdatedBy?: string; // Usando ? para indicar que es opcional
  createdAt: string;
  updatedAt: string;
  housesFlats: IHouseFlat[];
}

export class StreetBuilding {
  streetBuildingId: string;
  neighborhoodId: string;
  name: string;
  uidCreatedBy?: string; // Usando ? para indicar que es opcional
  uidUpdatedBy?: string; // Usando ? para indicar que es opcional
  createdAt: Date;
  updatedAt: Date;
  housesFlats: HouseFlat[];

  constructor(data: IStreetBuilding) {
    this.streetBuildingId = data.streetBuildingId;
    this.neighborhoodId = data.neighborhoodId;
    this.name = data.name;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.housesFlats = data.housesFlats
      ? data.housesFlats.map((houseFlat) => new HouseFlat(houseFlat))
      : [];
  }

  get activeHousesFlats(): number {
    return this.housesFlats.filter((houseFlat) => houseFlat.status).length;
  }

  get inactiveHouseFlat(): number {
    return this.housesFlats.filter((houseFlat) => !houseFlat.status).length;
  }
}
