import { Injectable } from '@angular/core';
import { AppUser, IAppUser } from '../models/app-user.model';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from './spinner.service';
import { ToastService } from './toast.service';
import { environment } from '../../environments/environment';
import { IServerResponse, ServerResponse } from '../models/server-response.model';

@Injectable({
  providedIn: 'root'
})
export class AppGuardService {

  public loading: boolean = false;
    public guards: AppUser[] = [];

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    public spinnerService: SpinnerService,
    public toastService: ToastService
  ) { }

  /*
   ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
   ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async create(
    neighborhoodId: string,
  ): Promise<AppUser | undefined> {
      
    let appUser = undefined;

    const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/guards`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Creando vigilante';

    try {

      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse<{user: IAppUser}>>(
        (resolve, reject) => {
          this.http.post<IServerResponse<{user: IAppUser}>>(
            urlApi,
            {},
            {
              headers: {
                'Authorization': token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse<{user: IAppUser}>
              ) => resolve(
                new ServerResponse<{user: IAppUser}>(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show(
                  {
                    header: 'Error de servidor',
                    body: `${error}`,
                    classname: 'bg-danger text-white'
                  }
                );
              }
            },
          });
        }
      );

      console.log(response);

      if(response.statusCode === 201) {
        appUser = new AppUser(response.data!.user);

        this.toastService.show(
          {
            body: `${response.message}`,
            classname: 'bg-success text-white'
          }
        );

        this.getAll(
          neighborhoodId
        );

      } else {

        this.toastService.show(
          {
            header: 'Error de servidor',
            body: `${response.message}`,
            classname: 'bg-danger text-white'
          }
        );

      }

    } catch(error) {
      console.log(error);

      this.toastService.show(
        {
          header: 'Error',
          body: `${error}`,
          classname: 'bg-danger text-white'
        }
      );
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return appUser;

  }

  /*
   ██████╗ ███████╗████████╗ █████╗ ██╗     ██╗
  ██╔════╝ ██╔════╝╚══██╔══╝██╔══██╗██║     ██║
  ██║  ███╗█████╗     ██║   ███████║██║     ██║
  ██║   ██║██╔══╝     ██║   ██╔══██║██║     ██║
  ╚██████╔╝███████╗   ██║   ██║  ██║███████╗███████╗
   ╚═════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚══════╝╚══════╝

  */
  async getAll(
    neighborhoodId: string
  ): Promise<AppUser[]> {

    let guards: AppUser[] = [];

    this.loading = true;
    const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/guards`;

    try {

      const token = await this.authService.getToken();
      
      const response = await new Promise<ServerResponse<{ users: IAppUser[] }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ users: IAppUser[] }>>(
            urlApi,
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (response) => resolve(new ServerResponse(response)),
            error: (error) => reject(error),
          });
        }
      );

      if (response.statusCode === 200) {
        this.guards = response.data!.users.map(
          user => new AppUser(user)
        );
      }

    } catch(error) {
      this.toastService.show({
        header: 'Error de servidor',
        body: `${error}`,
        classname: 'bg-danger text-white'
      });
    }

    this.loading = false;

    return guards;

  }
}
