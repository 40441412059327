import { Injectable } from '@angular/core';
import { IVisit, Visit } from '../models/visit.model';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from './spinner.service';
import { ToastService } from './toast.service';
import { IServerResponse, ServerResponse } from '../models/server-response.model';

@Injectable({
  providedIn: 'root'
})
export class VisitService {

  public loading: boolean = false;
  public actives: Visit[] = [];
  public historical: Visit[] = [];

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    public spinnerService: SpinnerService,
    public toastService: ToastService
  ) { }

  /*
     ██████╗ ███████╗████████╗ █████╗ ██╗     ██╗
    ██╔════╝ ██╔════╝╚══██╔══╝██╔══██╗██║     ██║
    ██║  ███╗█████╗     ██║   ███████║██║     ██║
    ██║   ██║██╔══╝     ██║   ██╔══██║██║     ██║
    ╚██████╔╝███████╗   ██║   ██║  ██║███████╗███████╗
     ╚═════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚══════╝╚══════╝
  
    */
    /**
     * FUNCTION getAll
     */
    async getAll(
      neighborhoodId: string,
    ) {
  
      this.loading = true;
      this.actives = [];
      this.historical = [];
  
      const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/visits`;
      try {
  
        const token = await this.authService.getToken();
  
        const response = await new Promise<ServerResponse<{ actives: IVisit[], historical: IVisit[] }>>(
          (resolve, reject) => {
            this.http.get<IServerResponse<{actives: IVisit[], historical: IVisit[] }>>(
              urlApi,
              {
                headers: {
                  "content-type": "application/json",
                  "Authorization": token.toString()
                }
              }
            ).subscribe({
              next: (
                  response: IServerResponse<{ actives: IVisit[], historical: IVisit[] }>
                ) => resolve(
                  new ServerResponse<{ actives: IVisit[], historical: IVisit[] }>(response)
                ),
              error: (error) => {
                if (error.error) {
                  resolve(new ServerResponse(error.error));
                } else {
                  reject(error);
                }
              },
            });
          }
        );
  
        console.log(response);
  
        if(response.statusCode === 200) {
          this.actives = response.data!.actives.map(
            (visit) => new Visit(visit)
          );

          this.historical = response.data!.historical.map(
            (visit) => new Visit(visit)
          );

        } else {
  
          this.toastService.show(
            {
              header: 'Error de servidor',
              body: `${response.message}`,
              classname: 'bg-danger text-white'
            }
          );
  
        }
  
      } catch(error) {
        console.log(error);
  
        this.toastService.show(
          {
            header: 'Error',
            body: `${error}`,
            classname: 'bg-danger text-white'
          }
        );
        
      }
  
      this.loading = false;
  
    }
}
