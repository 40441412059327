import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AnnouncementService } from '../../services/announcement.service';
import { AnnouncementTypeService } from '../../services/announcement-type.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-create-announcement-modal',
  templateUrl: './create-announcement-modal.component.html',
  styleUrl: './create-announcement-modal.component.scss'
})
export class CreateAnnouncementModalComponent {

  @Input() neighborhoodId: string = '';

  public formAnnouncement: FormGroup;
  public submitted: boolean = false;

  public uploadedFiles: string[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    public announcementService: AnnouncementService,
    public announcementTypeService: AnnouncementTypeService,
    private formBuilder: FormBuilder,
  ) {
    this.formAnnouncement = this.formBuilder.group({
      announcementType: [null, Validators.required],
      title: ['', Validators.required],
      file: [],
      content: ['', Validators.required],
    });
  }

  get formControls() {
    return this.formAnnouncement.controls;
  } 

  /*
   ██████╗ ███╗   ██╗███████╗██╗   ██╗██████╗ ███╗   ███╗██╗████████╗
  ██╔═══██╗████╗  ██║██╔════╝██║   ██║██╔══██╗████╗ ████║██║╚══██╔══╝
  ██║   ██║██╔██╗ ██║███████╗██║   ██║██████╔╝██╔████╔██║██║   ██║
  ██║   ██║██║╚██╗██║╚════██║██║   ██║██╔══██╗██║╚██╔╝██║██║   ██║
  ╚██████╔╝██║ ╚████║███████║╚██████╔╝██████╔╝██║ ╚═╝ ██║██║   ██║
   ╚═════╝ ╚═╝  ╚═══╝╚══════╝ ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝   ╚═╝

  */
  async onSubmit() {
    this.submitted = true;
    if (this.formAnnouncement.invalid) {
      return;
    }

    console.log(this.uploadedFiles);
    

    const announcement = await this.announcementService.create(
      this.neighborhoodId,
      this.formAnnouncement.value
    );

    console.log(announcement);

    if(announcement) {
      // this.activeModal.close();

      for(let i = 0; i < this.uploadedFiles.length; i++) {
        const file = this.uploadedFiles[i];

        let fileCategory = 'image';

        if(file.startsWith('data:application/pdf')) {
          fileCategory = 'pdf';
        }

        const flag = await this.announcementService.uploadFile(
          announcement,
          file,
          fileCategory
        );

        console.log(flag);
      }

      this.activeModal.close();

    }
  }

  async onFileChange(
    event: any
  ) {

    console.log(event);

    const files = event.target.files;
    this.uploadedFiles = [];
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onload = (e: any) => {
          const base64File = e.target.result;
          this.uploadedFiles.push(base64File);
        };
        reader.readAsDataURL(file);
      }

      
      
    }
    

  }

}
