import { Component } from '@angular/core';
import { MENU_NEIGH } from '../../data/menu.data';
import { Menu } from '../../models/menu.model';
import { SlideMenuService } from '../../services/slide-menu.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NeighborhoodService } from '../../services/neighborhood.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-neighborhood-layout',
  templateUrl: './neighborhood-layout.component.html',
  styleUrl: './neighborhood-layout.component.scss'
})
export class NeighborhoodLayoutComponent {

  public outerHeight: number = document.documentElement.clientHeight;

  public menu: Array<Menu> = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    public neighborhoodService: NeighborhoodService,
    private route: Router,
    public slideMenuService: SlideMenuService
  ) {    

    // this.menu[0].route = this.route.url;

    // console.log(this.menu[0]);

    this.activatedRoute.firstChild?.params.subscribe((params) => {

      this.neighborhoodService.get(
        params['neighborhoodId']
      )

    });

    if(this.authService.currentUser) {
      this.checkRoleMenus();
    } else {
      this.authService.subCurrentUser.subscribe((user) => {

        this.checkRoleMenus();
      });
    }

  }

  ngOnInit() {

  }

  /*
   ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗███╗   ███╗███████╗███╗   ██╗██╗   ██╗██╗    ██╗██╗████████╗██╗  ██╗██████╗  █████╗ ██████╗  █████╗ ███╗   ███╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝████╗ ████║██╔════╝████╗  ██║██║   ██║██║    ██║██║╚══██╔══╝██║  ██║██╔══██╗██╔══██╗██╔══██╗██╔══██╗████╗ ████║██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗  ██╔████╔██║█████╗  ██╔██╗ ██║██║   ██║██║ █╗ ██║██║   ██║   ███████║██████╔╝███████║██████╔╝███████║██╔████╔██║███████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝  ██║╚██╔╝██║██╔══╝  ██║╚██╗██║██║   ██║██║███╗██║██║   ██║   ██╔══██║██╔═══╝ ██╔══██║██╔══██╗██╔══██║██║╚██╔╝██║╚════██║
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗██║ ╚═╝ ██║███████╗██║ ╚████║╚██████╔╝╚███╔███╔╝██║   ██║   ██║  ██║██║     ██║  ██║██║  ██║██║  ██║██║ ╚═╝ ██║███████║
   ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝╚═╝     ╚═╝╚══════╝╚═╝  ╚═══╝ ╚═════╝  ╚══╝╚══╝ ╚═╝   ╚═╝   ╚═╝  ╚═╝╚═╝     ╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝     ╚═╝╚══════╝

  */
  createMenuWithParams() {
    this.activatedRoute.firstChild?.params.subscribe((params) => {
      console.log(params);

      const neighborhoodId = params['neighborhoodId'];

      console.log(neighborhoodId);

      console.log(MENU_NEIGH);
      

      // let menu = MENU_NEIGH.map((menuItem) => menuItem);
      let menu = this.menu.map(
        (menuItem) => (
          { 
            ...menuItem, 
            children: menuItem.children 
              ? menuItem.children.map(
                (child) => (
                  { 
                    ...child 
                  }
                )
              ) 
              : undefined 
          }
        )
      );
      

      menu.forEach((menuItem) => {

        if(menuItem.route) {
  
          menuItem.route = menuItem.route.replace(':neighborhoodId', neighborhoodId);
  
        } else {
  
          menuItem.children?.forEach((submenuItem) => {
  
            if(submenuItem.route) {
  
              submenuItem.route = submenuItem.route.replace(':neighborhoodId', neighborhoodId);
  
            }
  
          });
  
        }
  
      });

      this.menu = menu;
      
      
    });
  }

  /*
   ██████╗██╗  ██╗███████╗ ██████╗██╗  ██╗██████╗  ██████╗ ██╗     ███████╗███╗   ███╗███████╗███╗   ██╗██╗   ██╗███████╗
  ██╔════╝██║  ██║██╔════╝██╔════╝██║ ██╔╝██╔══██╗██╔═══██╗██║     ██╔════╝████╗ ████║██╔════╝████╗  ██║██║   ██║██╔════╝
  ██║     ███████║█████╗  ██║     █████╔╝ ██████╔╝██║   ██║██║     █████╗  ██╔████╔██║█████╗  ██╔██╗ ██║██║   ██║███████╗
  ██║     ██╔══██║██╔══╝  ██║     ██╔═██╗ ██╔══██╗██║   ██║██║     ██╔══╝  ██║╚██╔╝██║██╔══╝  ██║╚██╗██║██║   ██║╚════██║
  ╚██████╗██║  ██║███████╗╚██████╗██║  ██╗██║  ██║╚██████╔╝███████╗███████╗██║ ╚═╝ ██║███████╗██║ ╚████║╚██████╔╝███████║
   ╚═════╝╚═╝  ╚═╝╚══════╝ ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝ ╚═════╝ ╚══════╝╚══════╝╚═╝     ╚═╝╚══════╝╚═╝  ╚═══╝ ╚═════╝ ╚══════╝

  */
  checkRoleMenus() {
    if(this.menu.length === 0) {

      let menus = MENU_NEIGH;

      for(let menu of menus) {

        if(menu.rolesCanAccess) {
          if(menu.rolesCanAccess.includes(this.authService.currentUser!.profile)) {
            this.menu.push(menu);
          }
        } else if(menu.children) {
          
          menu.children = menu.children.filter(
            (child) => child.rolesCanAccess!.includes(this.authService.currentUser!.profile)
          );

          this.menu.push(menu);

        } else {
          this.menu.push(menu);
        }

      }

      this.createMenuWithParams();

    }
  }

}
