// EmergencyNumber.model.ts

export interface IEmergencyNumber {
  emergencyNumberId: string;
  neighborhoodId: string;
  phoneNumber: string;
  description: string;
  status: boolean;
  uidCreatedBy?: string; // Opcional
  uidUpdatedBy?: string; // Opcional
  createdAt: string;
  updatedAt: string;
}

export class EmergencyNumber {
  emergencyNumberId: string;
  neighborhoodId: string;
  phoneNumber: string;
  description: string;
  status: boolean;
  uidCreatedBy?: string; // Opcional
  uidUpdatedBy?: string; // Opcional
  createdAt: Date;
  updatedAt: Date;

  constructor(data: IEmergencyNumber) {
    this.emergencyNumberId = data.emergencyNumberId;
    this.neighborhoodId = data.neighborhoodId;
    this.phoneNumber = data.phoneNumber;
    this.description = data.description;
    this.status = data.status;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
  }
}
