@if(neighborhoodService.currentNeighborhood) {
  <div class="container-fluid pt-4">
    <div class="row">
  
      @if(authService.currentUser?.profile === 'sudo') {
        <div class="col-12 text-secondary mb-1">
          <small><b>
            Datos de configuración
          </b></small>
        </div>
    
        <div class="col-12 bg-white px-3 rounded mb-4 py-3">
          
        </div>
      }
  
      <div class="col-12 text-secondary mb-1">
        <small><b>
          Pruebas de accesos
        </b></small>
      </div>
  
      <div class="col-12 bg-white px-3 rounded mb-4 py-3">
        <div class="row">
  
          <div class="col-12">
            <div class="row">
              
              <div class="col-8 d-flex align-items-center">
                Requiere de subir pruebas de accesos
              </div>
  
              <div class="col-4 text-end d-flex align-items-center justify-content-end">
                <p-inputSwitch
                  [(ngModel)]="this.neighborhoodService.currentNeighborhood!.proofsRequired"
                  (onChange)="update()"></p-inputSwitch>
              </div>
  
            </div>
          </div>
  
        </div>
      </div>
  
      <div class="col-12 text-secondary mb-1">
        <small><b>
          Campos requeridos para visitas vehiculares y proveedores 
        </b></small>
      </div>
  
      <div class="col-12 bg-white px-3 rounded mb-4 pt-3">
        <div class="row">

          <div class="col-12 col-md-6 mb-3">
            <div class="row">
              
              <div class="col-8 d-flex align-items-center">
                Placas
              </div>
  
              <div class="col-4 text-end d-flex align-items-center justify-content-end">
                <p-inputSwitch
                  [(ngModel)]="this.neighborhoodService.currentNeighborhood!.platesRequired"
                  (onChange)="update()"></p-inputSwitch>
              </div>
  
            </div>
          </div>

          <div class="col-12 col-md-6 mb-3">
            <div class="row">
              
              <div class="col-8 d-flex align-items-center">
                Marca
              </div>
  
              <div class="col-4 text-end d-flex align-items-center justify-content-end">
                <p-inputSwitch
                  [(ngModel)]="this.neighborhoodService.currentNeighborhood!.brandRequired"
                  (onChange)="update()"></p-inputSwitch>
              </div>
  
            </div>
          </div>

          <div class="col-12 col-md-6 mb-3">
            <div class="row">
              
              <div class="col-8 d-flex align-items-center">
                Modelo
              </div>
  
              <div class="col-4 text-end d-flex align-items-center justify-content-end">
                <p-inputSwitch
                  [(ngModel)]="this.neighborhoodService.currentNeighborhood!.modelRequired"
                  (onChange)="update()"></p-inputSwitch>
              </div>
  
            </div>
          </div>

          <div class="col-12 col-md-6 mb-3">
            <div class="row">
              
              <div class="col-8 d-flex align-items-center">
                Color
              </div>
  
              <div class="col-4 text-end d-flex align-items-center justify-content-end">
                <p-inputSwitch
                  [(ngModel)]="this.neighborhoodService.currentNeighborhood!.colorRequired"
                  (onChange)="update()"></p-inputSwitch>
              </div>
  
            </div>
          </div>

        </div>
      </div>
  
      <div class="col-12 text-secondary mb-1">
        <small><b>
          Configuración de usuarios 
        </b></small>
      </div>
  
      <div class="col-12 bg-white px-3 rounded mb-4 py-3">
        <div class="row">

          <div class="col-12 col-md-6 mb-3">
            <label class="form-label">
              Número máximo de usuarios por predio
            </label>
            <input
              type="number"
              class="form-control"
              (keyup.enter)="update()"
              [(ngModel)]="this.neighborhoodService.currentNeighborhood!.accountsPerProperty"
              placeholder="Número máximo de usuarios por predio">
          </div>

          <div class="col-12 col-md-6 mb-3">
            <label class="form-label">
              Número máximo de dispositivos por usuario
            </label>
            <input
              type="number"
              class="form-control"
              (keyup.enter)="update()"
              [(ngModel)]="this.neighborhoodService.currentNeighborhood!.devicesPerProperty"
              placeholder="Número máximo de dispositivos por usuario">
          </div>

          <div class="col-12">
            <button
              class="btn btn-success w-100 d-flex align-items-center justify-content-center"
              (click)="update()">
              <i class="material-icons me-2">save</i>
              Guardar
            </button>
          </div>
          
        </div>
      </div>
  
      <div class="col-12 text-secondary mb-1">
        <small><b>
          Configuración pagos 
        </b></small>
      </div>
  
      <div class="col-12 bg-white px-3 rounded mb-4 py-3">
        <div class="row">

          <div class="col-12 col-md-6 mb-3">
            <label class="form-label">
              Costo de mantenimiento
            </label>
            <input
              type="number"
              class="form-control"
              (keyup.enter)="update()"
              [(ngModel)]="this.neighborhoodService.currentNeighborhood!.maintenanceFee"
              placeholder="Costo de mantenimiento">
          </div>

          <div class="col-12 col-md-6 mb-3">
            <label class="form-label">
              Fecha máximo de pago
            </label>
            <input
              type="number"
              class="form-control"
              (keyup.enter)="update()"
              [(ngModel)]="this.neighborhoodService.currentNeighborhood!.payday"
              placeholder="Fecha máximo de pago">
          </div>

          <div class="col-12">
            <button
              class="btn btn-success w-100 d-flex align-items-center justify-content-center"
              (click)="update()">
              <i class="material-icons me-2">save</i>
              Guardar
            </button>
          </div>
          
        </div>
      </div>
  
    </div>
  </div>
}