import { Component, Input } from '@angular/core';
import { Service } from '../../models/service.model';
import { DataFormModel } from '../../models/data-form-model.model';
import { FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from '../../services/util.service';
import { ServiceService } from '../../services/service.service';

@Component({
  selector: 'app-service-modal',
  templateUrl: './service-modal.component.html',
  styleUrl: './service-modal.component.scss'
})
export class ServiceModalComponent {

  @Input() service?: Service;
  @Input() neighborhoodId: string = '';

  public dataForm: DataFormModel = {
    image: {
      value: '',
      type: 'image',
      label: '',
      required: false,
      validators: []
    },
    service: {
      value: '',
      type: 'text',
      label: 'Nombre',
      requiredMessage: 'Por favor, ingrese el nombre',
      required: true,
      validators: [Validators.required]
    },
    status: {
      value: false,
      type: 'switch',
      label: 'Publicado',
      requiredMessage: '',
      required: false,
    },
    
  };

  public submitted: boolean = false;
  public loading: boolean = false;

  public formService!: FormGroup;

  public imageBase64: string | null = null;

  constructor(
    public activeModal: NgbActiveModal,
    public serviceService: ServiceService,
    public utilService: UtilService,
  ) {}

  ngOnInit(): void {
    this.loadData();
  }

  get f() {
    return this.formService.controls;
  }


  /*
   ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
   ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
   async create() {

    this.formService.value.imageBase64 = this.imageBase64;

    const flag = await this.serviceService.create(
      this.neighborhoodId,
      this.formService.value,
    );

    if(flag) {
      for(let key of Object.keys(this.dataForm)) {
        this.dataForm[key].value = '';
      }

      this.activeModal.close();
    }

  }

  /*
  ██╗      ██████╗  █████╗ ██████╗ ██████╗  █████╗ ████████╗ █████╗
  ██║     ██╔═══██╗██╔══██╗██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗
  ██║     ██║   ██║███████║██║  ██║██║  ██║███████║   ██║   ███████║
  ██║     ██║   ██║██╔══██║██║  ██║██║  ██║██╔══██║   ██║   ██╔══██║
  ███████╗╚██████╔╝██║  ██║██████╔╝██████╔╝██║  ██║   ██║   ██║  ██║
  ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝

  */
  async loadData() {
    this.loading = true;

    if(this.service) {

      this.dataForm = {
        ...this.dataForm,
      }

      for(let key of Object.keys(this.dataForm)) {

        if (key in this.service!) {
          const standardKey = key as keyof typeof this.service;
      
          // Ahora puedes acceder a `this.machine[machineKey]` sin errores de tipo.
          this.dataForm[key].value = this.service[standardKey]!;
        }
      } 
    }
    
    this.formService = this.utilService.buildForm(this.dataForm);

    this.loading = false;
  }

  /*
   ██████╗ ███╗   ██╗███████╗██╗██╗     ███████╗ ██████╗██╗  ██╗ █████╗ ███╗   ██╗ ██████╗ ███████╗
  ██╔═══██╗████╗  ██║██╔════╝██║██║     ██╔════╝██╔════╝██║  ██║██╔══██╗████╗  ██║██╔════╝ ██╔════╝
  ██║   ██║██╔██╗ ██║█████╗  ██║██║     █████╗  ██║     ███████║███████║██╔██╗ ██║██║  ███╗█████╗
  ██║   ██║██║╚██╗██║██╔══╝  ██║██║     ██╔══╝  ██║     ██╔══██║██╔══██║██║╚██╗██║██║   ██║██╔══╝
  ╚██████╔╝██║ ╚████║██║     ██║███████╗███████╗╚██████╗██║  ██║██║  ██║██║ ╚████║╚██████╔╝███████╗
   ╚═════╝ ╚═╝  ╚═══╝╚═╝     ╚═╝╚══════╝╚══════╝ ╚═════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═══╝ ╚═════╝ ╚══════╝

  */
  /**
   * FUNCTION onFileChange
   * 
   * @param event 
   */
  onFileChange(event: any) {
    console.log(event);

    const file = event.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(file); // toBase64
    reader.onload = () => {
      this.imageBase64 = reader.result as string; // base64 Image src
      console.log(this.imageBase64);
      
    };
    
  }

  /*
   ██████╗ ███╗   ██╗███████╗██╗   ██╗██████╗ ███╗   ███╗██╗████████╗
  ██╔═══██╗████╗  ██║██╔════╝██║   ██║██╔══██╗████╗ ████║██║╚══██╔══╝
  ██║   ██║██╔██╗ ██║███████╗██║   ██║██████╔╝██╔████╔██║██║   ██║
  ██║   ██║██║╚██╗██║╚════██║██║   ██║██╔══██╗██║╚██╔╝██║██║   ██║
  ╚██████╔╝██║ ╚████║███████║╚██████╔╝██████╔╝██║ ╚═╝ ██║██║   ██║
   ╚═════╝ ╚═╝  ╚═══╝╚══════╝ ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝   ╚═╝

  */
  async onSubmit() {

    console.log(this.formService.value);
    
    this.submitted = true;

    if(this.formService.invalid) {
      return;
    }

    if(this.service) {

      await this.update();
    } else {
      await this.create();
    }
  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
   ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async update() {

    this.formService.value.serviceId = this.service!.serviceId;
    this.formService.value.neighborhoodId = this.service!.neighborhoodId;

    this.formService.value.imageBase64 = this.imageBase64;

    const flag = await this.serviceService.update(
      this.formService.value
    );

    if(flag) {

      for(let key of Object.keys(this.dataForm)) {
        this.dataForm[key].value = '';
      }

      this.activeModal.close();
    }
  }

}
