<div class="modal-header">
  <h4 class="modal-title">
    Nueva anuncio
  </h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  
  <form
    id="formAnnouncement"
    [formGroup]="formAnnouncement"
    (ngSubmit)="onSubmit()">
    <div class="container-fluid">
      <div class="row">

        <div class="col-12 mb-3">
          <label for="announcementType" class="form-label">Tipo de anuncio</label>
            <ng-select
            [items]="announcementTypeService.announcementTypes"
            bindLabel="description"
            bindValue="announcementType"
            formControlName="announcementType"
            placeholder="Seleccione un tipo de anuncio"
            [loading]="announcementTypeService.loading"
            [ngClass]="{
              'is-invalid': submitted && formControls['announcementType'].errors
            }">
            </ng-select>
            <div *ngIf="submitted && formControls['announcementType'].errors" class="invalid-feedback">
              <div *ngIf="formControls['announcementType'].errors['required']">
                Por favor, seleccione el tipo de anuncio
              </div>
            </div>
        </div>

        <div class="col-12 mb-3">
          <label for="uploadedImages" class="form-label">Imágenes cargadas</label>
          <div id="uploadedImages" class="d-flex flex-wrap">
            <div *ngFor="let file of uploadedFiles" class="p-2">
                <img *ngIf="file.startsWith('data:image/')" [src]="file" alt="Imagen cargada" class="img-thumbnail" style="max-width: 150px; max-height: 150px;">
            </div>
          </div>
        </div>

        <div class="col-12 mb-3">
          <label for="file" class="form-label">Agregar imagen(es) o PDF</label>
          <input
            type="file"
            id="file"
            class="form-control"
            formControlName="file"
            accept=".jpg,.jpeg,.png,.pdf"
            (change)="onFileChange($event)"
            multiple>
        </div>

        <div class="col-12 mb-3 form-group">
          <label for="title" class="form-label">Título</label>
          <input
            type="text"
            id="title"
            class="form-control"
            formControlName="title"
            placeholder="Ingrese el título del anuncio"
            [ngClass]="{
              'is-invalid': submitted && formControls['title'].errors
            }">
          <div *ngIf="submitted && formControls['title'].errors" class="invalid-feedback">
            <div *ngIf="formControls['title'].errors['required']">
              Por favor, ingrese el título del anuncio
            </div>
          </div>
        </div>

        <div class="col-12 mb-3 form-group">
          <label for="content" class="form-label">Contenido</label>
          <textarea
            id="content"
            class="form-control"
            formControlName="content"
            rows="5"
            placeholder="Ingrese el contenido del anuncio"
            [ngClass]="{
              'is-invalid': submitted && formControls['content'].errors
            }"></textarea>
          <div *ngIf="submitted && formControls['content'].errors" class="invalid-feedback">
            <div *ngIf="formControls['content'].errors['required']">
              Por favor, ingrese el contenido del anuncio
            </div>
          </div>
        </div>

      </div>
    </div>
  </form>

</div>
<div class="modal-footer">
  <div class="col-12 my-3">
    <button
      (click)="activeModal.close()"
      type="button"
      class="btn btn-outline-danger w-100">
      Cancelar
    </button>
  </div>
  <div class="col-12">
    <button
      type="submit"
      form="formAnnouncement"
      class="btn btn-primary w-100">
      Publicar
    </button>
  </div>
</div>