<div class="container-fluid">
  <div class="row">

    <a 
      class="col-12 bg-white py-3 px-3 rounded mb-4 text-decoration-none text-dark" 
      [routerLink]="[charge?.chargeId]">
      <div class="row">

        <div class="col d-flex align-items-center">
          <h4 class="m-0"><b>
            {{ charge?.createdAt | dateToMoment: 'MMMM YYYY' }}
          </b></h4>
        </div>

        <div class="col">
          <div class="row">

            <div class="col text-danger d-flex align-items-center">
              <i class="material-icons me-2">cancel</i>{{ charge?.overdue }}
            </div>
            <div class="col text-secondary d-flex align-items-center">
              <i class="material-icons me-2">pending</i>{{ charge?.pending }}
            </div>
            <div class="col text-success d-flex align-items-center">
              <i class="material-icons me-2">check_circle</i>{{ charge?.done }}
            </div>

          </div>
        </div>

        <div class="col text-center">
          {{ charge?.charge | currency }}
        </div>

        <div class="col d-flex justify-content-end align-items-center">
          <i class="material-icons text-secondary">more_vert</i>
        </div>

      </div>
    </a>

  </div>
</div>