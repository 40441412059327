<div class="container-fluid py-3">
  <div class="row">

    @if(authService.currentUser?.profile == 'sudo') {

      <div class="col-12">
        <div class="col-12 bg-white py-3 px-3 rounded mb-3 d-flex">
          <button
            class="btn btn-outline-primary d-flex align-items-center justify-content-center me-2"
            type="button">
            <i class="material-icons me-2">keyboard_backspace</i> Importar casas
          </button>
  
          <button
            class="btn btn-outline-primary d-flex align-items-center justify-content-center"
            type="button">
            <i class="material-icons me-2">keyboard_backspace</i> Importar calles y casas
          </button>
  
        </div>
      </div>

    }

    <div class="col-12">
      <div class="col-12 bg-white py-3 px-3 rounded mb-5">
        <div class="row">
          <div class="col">
            <div class="row">
  
              <div class="col-12 text-secondary">
                <small><b>No. Casas</b></small>
              </div>
              <div class="col-12 fw-100">
                <h3>{{ getTotalHousesFlats() }}</h3>
              </div>
  
            </div>
          </div>
          <div class="col">
            <div class="row">
  
              <div class="col-12 text-secondary">
                <small><b>Activos</b></small>
              </div>
              <div class="col-12 text-success fw-100">
                <h3>{{ getTotalActiveHousesFlats() }}</h3>
              </div>
  
            </div>
          </div>
          <div class="col">
            <div class="row">
  
              <div class="col-12 text-secondary">
                <small><b>Inactivos</b></small>
              </div>
              <div class="col-12 text-danger fw-100">
                <h3>{{ getTotalInactiveHousesFlats() }}</h3>
              </div>
  
            </div>
          </div>
        </div>
      </div>
    </div>

    @for (streetBuilding of streetBuildingService.streetsBuildings; track $index) {

      <app-street-building-item
        [streetBuilding]="streetBuilding">
      </app-street-building-item>

    }

  </div>
</div>

<button
  (click)="open()"
  class="btn btn-sm btn-success rounded-circle me-2 my-1 position-fixed"
  style="bottom: 2rem; right: 2rem;">
  <i class="material-icons mt-1">add</i>
</button>
