import { Injectable } from '@angular/core';
import { BankAccountType, IBankAccountType } from '../models/bank-account-type.model';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from './spinner.service';
import { ToastService } from './toast.service';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { IServerResponse, ServerResponse } from '../models/server-response.model';

@Injectable({
  providedIn: 'root'
})
export class BankAccountTypeService {

  public loading: boolean = false;
  public bankAccountTypes: BankAccountType[] = [];

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private spinnerService: SpinnerService,
    private toastService: ToastService,
  ) {
    this.getAll();
  }

  /**
   * Obtener todos los tipos de cuentas bancarias
   */
  async getAll(): Promise<BankAccountType[]> {
    this.loading = true;
    const urlApi = `${environment.urlApi}/bank-account-types`;

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse<{ bankAccountTypes: IBankAccountType[] }>>(
        (resolve, reject) => {
          this.http
            .get<IServerResponse<{ bankAccountTypes: IBankAccountType[] }>>(urlApi, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: token.toString(),
              },
            })
            .subscribe({
              next: (response) =>
                resolve(new ServerResponse<{ bankAccountTypes: IBankAccountType[] }>(response)),
              error: (error) => {
                if (error.error) {
                  resolve(new ServerResponse(error.error));
                } else {
                  reject(error);
                }
              },
            });
        }
      );

      if (response.statusCode === 200) {
        this.bankAccountTypes = response.data!.bankAccountTypes.map(
          (item) => new BankAccountType(item)
        );
      } else {
        this.toastService.show({
          header: 'Error',
          body: response.message,
          classname: 'bg-danger text-white',
        });
      }
    } catch (error) {
      console.error(error);
      this.toastService.show({
        header: 'Error de servidor',
        body: `${error}`,
        classname: 'bg-danger text-white',
      });
    } finally {
      this.loading = false;
    }

    return this.bankAccountTypes;
  }

  /**
   * Crear un nuevo tipo de cuenta bancaria
   */
  async create(bankAccountType: IBankAccountType): Promise<boolean> {
    let flag = false;
    const urlApi = `${environment.urlApi}/bank-account-types`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Creando tipo de cuenta bancaria...';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.post<IServerResponse>(
            urlApi,
            JSON.stringify(bankAccountType),
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: token.toString(),
              },
            }
          ).subscribe({
            next: (response) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
              }
            },
          });
        }
      );

      if (response.statusCode === 201) {
        flag = true;
        this.getAll();
        this.toastService.show({
          body: response.message,
          classname: 'bg-success text-white',
        });
      } else {
        this.toastService.show({
          header: 'Error',
          body: response.message,
          classname: 'bg-danger text-white',
        });
      }
    } catch (error) {
      console.error(error);
      this.toastService.show({
        header: 'Error de servidor',
        body: `${error}`,
        classname: 'bg-danger text-white',
      });
    } finally {
      this.spinnerService.loading = false;
    }

    return flag;
  }

  /**
   * Actualizar un tipo de cuenta bancaria
   */
  async update(bankAccountType: BankAccountType): Promise<boolean> {
    let flag = false;
    const urlApi = `${environment.urlApi}/bank-account-types/${bankAccountType.bankAccountTypeId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Actualizando tipo de cuenta bancaria...';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.put<IServerResponse>(
            urlApi,
            JSON.stringify(bankAccountType),
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: token.toString(),
              },
            }
          ).subscribe({
            next: (response) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
              }
            },
          });
        }
      );

      if (response.statusCode === 200) {
        flag = true;
        this.getAll();
        this.toastService.show({
          body: response.message,
          classname: 'bg-success text-white',
        });
      } else {
        this.toastService.show({
          header: 'Error',
          body: response.message,
          classname: 'bg-danger text-white',
        });
      }
    } catch (error) {
      console.error(error);
      this.toastService.show({
        header: 'Error de servidor',
        body: `${error}`,
        classname: 'bg-danger text-white',
      });
    } finally {
      this.spinnerService.loading = false;
    }

    return flag;
  }

  /**
   * Eliminar un tipo de cuenta bancaria
   */
  async delete(bankAccountType: BankAccountType): Promise<boolean> {
    let flag = false;
    const urlApi = `${environment.urlApi}/bank-account-types/${bankAccountType.bankAccountTypeId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Eliminando tipo de cuenta bancaria...';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.delete<IServerResponse>(
            urlApi,
            {
              headers: {
                Authorization: token.toString(),
              },
            }
          ).subscribe({
            next: (response) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
              }
            },
          });
        }
      );

      if (response.statusCode === 200) {
        flag = true;
        this.getAll();
        this.toastService.show({
          body: response.message,
          classname: 'bg-success text-white',
        });
      } else {
        this.toastService.show({
          header: 'Error',
          body: response.message,
          classname: 'bg-danger text-white',
        });
      }
    } catch (error) {
      console.error(error);
      this.toastService.show({
        header: 'Error de servidor',
        body: `${error}`,
        classname: 'bg-danger text-white',
      });
    } finally {
      this.spinnerService.loading = false;
    }

    return flag;
  }

  /**
   * Obtener un tipo de cuenta bancaria por ID
   */
  async get(id: number): Promise<BankAccountType | null> {
    const urlApi = `${environment.urlApi}/bank-account-types/${id}`;
    let bankAccountType: BankAccountType | null = null;

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse<IBankAccountType>>(
        (resolve, reject) => {
          this.http
            .get<IServerResponse<IBankAccountType>>(urlApi, {
              headers: {
                Authorization: token.toString(),
              },
            })
            .subscribe({
              next: (response) => resolve(new ServerResponse<IBankAccountType>(response)),
              error: (error) => {
                if (error.error) {
                  resolve(new ServerResponse(error.error));
                } else {
                  reject(error);
                }
              },
            });
        }
      );

      if (response.statusCode === 200) {
        bankAccountType = new BankAccountType(response.data!);
      } else {
        this.toastService.show({
          header: 'Error',
          body: response.message,
          classname: 'bg-danger text-white',
        });
      }
    } catch (error) {
      console.error(error);
      this.toastService.show({
        header: 'Error de servidor',
        body: `${error}`,
        classname: 'bg-danger text-white',
      });
    }

    return bankAccountType;
  }
}
