import { Component, Input } from '@angular/core';
import { HouseFlat } from '../../models/house-flat.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StreetBuilding } from '../../models/street-building.model';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrl: './payment-modal.component.scss'
})
export class PaymentModalComponent {

  @Input() streetBuilding?: StreetBuilding;
  @Input() houseFlat?: HouseFlat;
  @Input() neighborhoodId: string = '';

  constructor(
    public activeModal: NgbActiveModal
  ) {}

}
