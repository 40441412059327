import { Component } from '@angular/core';

@Component({
  selector: 'app-street-building-payment-item',
  templateUrl: './street-building-payment-item.component.html',
  styleUrl: './street-building-payment-item.component.scss'
})
export class StreetBuildingPaymentItemComponent {

}
