<ul ngbNav #nav="ngbNav" class="nav-pills pt-3">
  <li ngbNavItem>
    <button ngbNavLink>Activos</button>
    <ng-template ngbNavContent>

      <div class="container-fluid">
        <div class="row pt-3">

          <app-visit-list
            [visits]="visitService.actives"
            [loading]="visitService.loading"
            [neighborhoodId]="neighborhoodId">
          </app-visit-list>

        </div>
      </div>

    </ng-template>
  </li>

  <li ngbNavItem>
    <button ngbNavLink>Historico</button>
    <ng-template ngbNavContent>

      <div class="container-fluid">
        <div class="row pt-3">

          <app-visit-list
            [visits]="visitService.historical"
            [loading]="visitService.loading"
            [neighborhoodId]="neighborhoodId"
            [enableReports]="true"
            [moreDetails]="true">
          </app-visit-list>

        </div>
      </div>

    </ng-template>
  </li>
</ul>
<div [ngbNavOutlet]="nav"></div>