import { Component } from '@angular/core';
import { Menu } from '../../models/menu.model';
import { MENU } from '../../data/menu.data';
import { SlideMenuService } from '../../services/slide-menu.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrl: './dashboard-layout.component.scss'
})
export class DashboardLayoutComponent {

  public outerHeight: number = document.documentElement.clientHeight;

  public menu: Array<Menu> = [];

  constructor(
    private authService: AuthService,
    public slideMenuService: SlideMenuService
  ) {

    // console.log(this.menu);
    
    if(this.authService.currentUser) {
      this.checkRoleMenus();
    } else {
      this.authService.subCurrentUser.subscribe((user) => {

        this.checkRoleMenus();
      });
    }

  }

  checkRoleMenus() {
    if(this.menu.length === 0) {
  
      const menus = MENU;

      for(let menu of menus) {

        if(menu.rolesCanAccess) {
          if(menu.rolesCanAccess.includes(this.authService.currentUser!.profile)) {
            this.menu.push(menu);
          }
        } else if(menu.children) {
          
        } else {
          this.menu.push(menu);
        }

      }

    }
  }

}
