import { Component } from '@angular/core';
import { BankAccountTypeService } from '../../../services/bank-account-type.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BankAccountType } from '../../../models/bank-account-type.model';
import { BankAccountTypeModalComponent } from '../../../components/bank-account-type-modal/bank-account-type-modal.component';

@Component({
  selector: 'app-bank-account-types-page',
  templateUrl: './bank-account-types-page.component.html',
  styleUrl: './bank-account-types-page.component.scss'
})
export class BankAccountTypesPageComponent {

  constructor(
    public bankAccountTypeService: BankAccountTypeService,
    private modalService: NgbModal,
  ) {
    
  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  async delete(
    bankAccountType: BankAccountType
  ) {

    const flag = confirm(
      `¿Esta seguro que desea borrar el tipo de cuenta bancaria ${bankAccountType.bankAccountType}?`
    );

    if(flag) {
      await this.bankAccountTypeService.delete(
        bankAccountType
      );
    }

  }

  /*
    ██████╗ ██████╗ ███████╗███╗   ██╗
  ██╔═══██╗██╔══██╗██╔════╝████╗  ██║
  ██║   ██║██████╔╝█████╗  ██╔██╗ ██║
  ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║
  ╚██████╔╝██║     ███████╗██║ ╚████║
    ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝

  */
  open(
    bankAccountType?: BankAccountType,
  ) {
    const modalRef = this.modalService.open(
      BankAccountTypeModalComponent
    );

    modalRef.componentInstance.bankAccountType = bankAccountType;

  }

}
