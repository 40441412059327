<div class="container-fluid py-3">
  <div class="row">

    <div class="col-12">
      <div class="col-12 bg-white py-3 px-3 rounded mb-3 d-flex justify-content-end">
        <button (click)="open()" class="btn btn-success rounded-circle me-2">
          <i class="material-icons mt-1">add</i>
        </button>
        <button (click)="bankAccountService.getAll(neighborhoodId!)" class="btn btn-primary rounded-circle me-2">
          <i class="material-icons mt-1">refresh</i>
        </button>

      </div>
    </div>

    @if(bankAccountService.loading && bankAccountService.bankAccounts.length === 0) {
      <div class="col-12 text-center">

        <div class="spinner-border" style="width: 3rem; height: 3rem;" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>

      </div>
    }

    @if (!bankAccountService.loading && bankAccountService.bankAccounts.length == 0) {
      <div class="col-12 text-center">
        <p class="text-secondary">No hay cuentas bancarias registradas</p>
      </div>
    }

    @for (bankAccount of bankAccountService.bankAccounts; track $index) {

      <app-bank-account-item
        [bankAccount]="bankAccount">
      </app-bank-account-item>

    }

  </div>
</div>