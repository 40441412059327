import { Component, Input } from '@angular/core';
import { BankAccountType } from '../../models/bank-account-type.model';
import { DataFormModel } from '../../models/data-form-model.model';
import { FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BankAccountTypeService } from '../../services/bank-account-type.service';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-bank-account-type-modal',
  templateUrl: './bank-account-type-modal.component.html',
  styleUrl: './bank-account-type-modal.component.scss'
})
export class BankAccountTypeModalComponent {

  @Input() bankAccountType?: BankAccountType;
  
    public dataForm: DataFormModel = {
      bankAccountType: {
        value: '',
        type: 'text',
        label: 'Nombre del tipo de cuenta bancaria',
        requiredMessage: 'Por favor, ingrese el nombre del tipo de cuenta bancaria',
        required: true,
        validators: [Validators.required]
      },
    };
  
    public submitted: boolean = false;
    public loading: boolean = false;
  
    public formBankAccountType!: FormGroup;
    
    constructor(
      public activeModal: NgbActiveModal,
      public bankAccountTypeService: BankAccountTypeService,
      public utilService: UtilService,
    ) {}
  
    ngOnInit(): void {
      this.loadData();
    }
  
    get f() {
      return this.formBankAccountType.controls;
    }
  
  
    /*
     ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
    ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
    ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
    ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
    ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
     ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝
  
    */
     async create() {
    
      const flag = await this.bankAccountTypeService.create(
        this.formBankAccountType.value,
      );
  
      if(flag) {
        for(let key of Object.keys(this.dataForm)) {
          this.dataForm[key].value = '';
        }
  
        this.activeModal.close();
      }
  
    }
  
    /*
    ██╗      ██████╗  █████╗ ██████╗ ██████╗  █████╗ ████████╗ █████╗
    ██║     ██╔═══██╗██╔══██╗██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗
    ██║     ██║   ██║███████║██║  ██║██║  ██║███████║   ██║   ███████║
    ██║     ██║   ██║██╔══██║██║  ██║██║  ██║██╔══██║   ██║   ██╔══██║
    ███████╗╚██████╔╝██║  ██║██████╔╝██████╔╝██║  ██║   ██║   ██║  ██║
    ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝
  
    */
    async loadData() {
      this.loading = true;
  
      if(this.bankAccountType) {
  
        this.dataForm = {
          ...this.dataForm,
        }
  
        for(let key of Object.keys(this.dataForm)) {
  
          if (key in this.bankAccountType!) {
            const standardKey = key as keyof typeof this.bankAccountType;
        
            // Ahora puedes acceder a `this.machine[machineKey]` sin errores de tipo.
            this.dataForm[key].value = this.bankAccountType[standardKey]!;
          }
        } 
      }
      
      this.formBankAccountType = this.utilService.buildForm(this.dataForm);
  
      this.loading = false;
    }

  
    /*
     ██████╗ ███╗   ██╗███████╗██╗   ██╗██████╗ ███╗   ███╗██╗████████╗
    ██╔═══██╗████╗  ██║██╔════╝██║   ██║██╔══██╗████╗ ████║██║╚══██╔══╝
    ██║   ██║██╔██╗ ██║███████╗██║   ██║██████╔╝██╔████╔██║██║   ██║
    ██║   ██║██║╚██╗██║╚════██║██║   ██║██╔══██╗██║╚██╔╝██║██║   ██║
    ╚██████╔╝██║ ╚████║███████║╚██████╔╝██████╔╝██║ ╚═╝ ██║██║   ██║
     ╚═════╝ ╚═╝  ╚═══╝╚══════╝ ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝   ╚═╝
  
    */
    async onSubmit() {
  
      console.log(this.formBankAccountType.value);
      
      this.submitted = true;
  
      if(this.formBankAccountType.invalid) {
        return;
      }
  
      if(this.bankAccountType) {
  
        await this.update();
      } else {
        await this.create();
      }
    }
  
    /*
    ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
    ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
    ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
    ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
    ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
     ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝
  
    */
    async update() {
  
      this.formBankAccountType.value.bankAccountTypeId = this.bankAccountType!.bankAccountTypeId;
    
      const flag = await this.bankAccountTypeService.update(
        this.formBankAccountType.value
      );
  
      if(flag) {
  
        for(let key of Object.keys(this.dataForm)) {
          this.dataForm[key].value = '';
        }
  
        this.activeModal.close();
      }
    }

}
