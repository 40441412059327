import { Injectable } from '@angular/core';
import { Announcement, IAnnouncement } from '../models/announcement.model';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from './spinner.service';
import { ToastService } from './toast.service';
import { environment } from '../../environments/environment';
import { IServerResponse, ServerResponse } from '../models/server-response.model';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {

  public loading: boolean = false;
  public announcements: Announcement[] = [];

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    public spinnerService: SpinnerService,
    public toastService: ToastService
  ) { }

  /*
   ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
   ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async create(
    neighborhoodId: string,
    announcement: IAnnouncement
  ): Promise<Announcement | null> {
    let announcementCreated: Announcement | null = null;
    
    const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/announcements`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Registrando anuncio...';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse<{announcement: IAnnouncement}>>(
        (resolve, reject) => {
          this.http.post<IServerResponse<{announcement: IAnnouncement}>>(
            urlApi,
            JSON.stringify(announcement),
            {
              headers: {
                'content-type': 'application/json',
                'Authorization': token.toString()
              }
            }
          ).subscribe({
            next: (response: IServerResponse) => resolve(
              new ServerResponse(response)
            ),
            error: (error) => {
              if (error.error) resolve(new ServerResponse(error.error));
              else reject(error);
            },
          });
        }
      );

      if(response.statusCode === 201) {
        
        announcementCreated = new Announcement(response.data!.announcement);

        this.toastService.show({ 
          body: `${response.message}`, 
          classname: 'bg-success text-white' 
        });
        this.getAll(
          neighborhoodId
        );

      } else {
        
        this.toastService.show({ 
          header: 'Error', 
          body: `${response.message}`, 
          classname: 'bg-danger text-white' 
        });

      }

    } catch(error) {
      this.toastService.show({ 
        header: 'Error', 
        body: `${error}`, 
        classname: 'bg-danger text-white' 
      });
    }

    this.spinnerService.loading = false;
    return announcementCreated;
  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  async delete(
    announcement: Announcement
  ): Promise<boolean> {
    let flag = false;
    const urlApi = `${environment.urlApi}/neighborhoods/${announcement.neighborhoodId}/announcements/${announcement.announcementId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Eliminando anuncio...';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.delete<IServerResponse>(
            urlApi,
            { 
              headers: { 
                'Authorization': token.toString() 
              } 
            }
          ).subscribe({
            next: (response: IServerResponse) => resolve(
              new ServerResponse(response)
            ),
            error: (error) => {
              if (error.error) resolve(new ServerResponse(error.error));
              else reject(error);
            },
          });
        }
      );

      if(response.statusCode === 200) {
        flag = true;

        this.toastService.show({ 
          body: `${response.message}`, 
          classname: 'bg-success text-white' 
        });

        this.getAll(
          announcement.neighborhoodId
        );

      } else {

        this.toastService.show({ 
          header: 'Error', 
          body: `${response.message}`, 
          classname: 'bg-danger text-white' 
        });

      }

    } catch(error) {

      this.toastService.show({ 
        header: 'Error', 
        body: `${error}`, 
        classname: 'bg-danger text-white' 
      });

    }

    this.spinnerService.loading = false;
    return flag;
  }

  /*
   ██████╗ ███████╗████████╗ █████╗ ██╗     ██╗
  ██╔════╝ ██╔════╝╚══██╔══╝██╔══██╗██║     ██║
  ██║  ███╗█████╗     ██║   ███████║██║     ██║
  ██║   ██║██╔══╝     ██║   ██╔══██║██║     ██║
  ╚██████╔╝███████╗   ██║   ██║  ██║███████╗███████╗
   ╚═════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚══════╝╚══════╝

  */
  async getAll(
    neighborhoodId: string
  ) {
    this.loading = true;
    const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/announcements`;

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse<{ announcements: IAnnouncement[] }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ announcements: IAnnouncement[] }>>(
            urlApi,
            { 
              headers: { 
                "content-type": "application/json", 
                "Authorization": token.toString() 
              } 
            }
          ).subscribe({
            next: (response) => resolve(new ServerResponse(response)),
            error: (error) => reject(error),
          });
        }
      );

      if (response.statusCode === 200) {
        this.announcements = response.data!.announcements.map(
          ann => new Announcement(ann)
        );
      }

    } catch(error) {

      this.toastService.show({ 
        header: 'Error', 
        body: `${error}`, 
        classname: 'bg-danger text-white' 
      });

    }

    this.loading = false;
  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
   ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async update(
    announcement: IAnnouncement
  ): Promise<boolean> {
    let flag = false;
    const urlApi = `${environment.urlApi}/neighborhoods/${announcement.neighborhoodId}/announcements/${announcement.announcementId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Actualizando anuncio...';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.put<IServerResponse>(
            urlApi,
            JSON.stringify(announcement),
            {
              headers: {
                'content-type': 'application/json',
                'Authorization': token.toString()
              }
            }
          ).subscribe({
            next: (response: IServerResponse) => resolve(
              new ServerResponse(response)
            ),
            error: (error) => {
              if (error.error) resolve(new ServerResponse(error.error));
              else reject(error);
            },
          });
        }
      );

      if (response.statusCode === 200) {

        flag = true;
        this.toastService.show({ 
          body: `${response.message}`, 
          classname: 'bg-success text-white' 
        });
        this.getAll(
          announcement.neighborhoodId
        );

      } else {
        this.toastService.show({ 
          header: 'Error', 
          body: `${response.message}`, 
          classname: 'bg-danger text-white' 
        });
      }

    } catch (error) {
      this.toastService.show({ 
        header: 'Error', 
        body: `${error}`, 
        classname: 'bg-danger text-white' 
      });
    }

    this.spinnerService.loading = false;
    return flag;
  }

  /*
  ██╗   ██╗██████╗ ██╗      ██████╗  █████╗ ██████╗ ███████╗██╗██╗     ███████╗
  ██║   ██║██╔══██╗██║     ██╔═══██╗██╔══██╗██╔══██╗██╔════╝██║██║     ██╔════╝
  ██║   ██║██████╔╝██║     ██║   ██║███████║██║  ██║█████╗  ██║██║     █████╗
  ██║   ██║██╔═══╝ ██║     ██║   ██║██╔══██║██║  ██║██╔══╝  ██║██║     ██╔══╝
  ╚██████╔╝██║     ███████╗╚██████╔╝██║  ██║██████╔╝██║     ██║███████╗███████╗
   ╚═════╝ ╚═╝     ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝ ╚═╝     ╚═╝╚══════╝╚══════╝

  */
  async uploadFile(
    announcement: Announcement,
    fileBase64: string,
    fileType: string
  ): Promise<boolean> {

    let flag = false;

    const urlApi = `${environment.urlApi}/neighborhoods/${announcement.neighborhoodId}/announcements/${announcement.announcementId}/files`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Subiendo archivo...';

    try {
      const token = await this.authService.getToken();

      console.log({ 
        imageBase64: fileBase64, 
        fileType 
      });
      

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.post<IServerResponse>(
            urlApi,
            JSON.stringify({ 
              imageBase64: fileBase64, 
              fileType 
            }),
            {
              headers: {
                'content-type': 'application/json',
                'Authorization': token.toString()
              }
            }
          ).subscribe({
            next: (response: IServerResponse) => resolve(
              new ServerResponse(response)
            ),
            error: (error) => {
              if (error.error) resolve(new ServerResponse(error.error));
              else reject(error);
            },
          });
        }
      );

      if (response.statusCode === 201) {

        flag = true;
        this.toastService.show({ 
          body: `${response.message}`, 
          classname: 'bg-success text-white' 
        });

        this.getAll(
          announcement.neighborhoodId
        );

      } else {
        this.toastService.show({ 
          header: 'Error', 
          body: `${response.message}`, 
          classname: 'bg-danger text-white' 
        });
      }

    } catch (error) {
      this.toastService.show({ 
        header: 'Error', 
        body: `${error}`, 
        classname: 'bg-danger text-white' 
      });
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return flag;

  }
  
}
