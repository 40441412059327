import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { SpinnerService } from './spinner.service';
import { ToastService } from './toast.service';
import { Charge, ICharge } from '../models/charge.model';
import { environment } from '../../environments/environment';
import { IServerResponse, ServerResponse } from '../models/server-response.model';

@Injectable({
  providedIn: 'root'
})
export class ChargeService {

  public loading: boolean = false;
  public charges: Charge[] = [];

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private spinnerService: SpinnerService,
    private toastService: ToastService,
  ) {}

  /*
   ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
   ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async create(
    neighborhoodId: string,
    charge: number
  ): Promise<boolean> {
    let success = false;
    const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/charges`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Creando cargo...';

    try {
      const token = await this.authService.getToken();
      const response = await new Promise<ServerResponse>((resolve, reject) => {
      this.http.post<IServerResponse>(
          urlApi, 
          JSON.stringify({ charge }), 
          {
            headers: {
              Authorization: token.toString(),
              'Content-Type': 'application/json',
            },
          }
        ).subscribe({
          next: (response) => resolve(new ServerResponse(response)),
          error: (error) => reject(error),
        });
      });

      if (response.statusCode === 201) {
        success = true;
        this.toastService.show({
          body: response.message,
          classname: 'bg-success text-white',
        });
        this.getAll(neighborhoodId);
      } else {
        this.toastService.show({
          header: 'Error',
          body: response.message,
          classname: 'bg-danger text-white',
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.spinnerService.loading = false;
      this.spinnerService.message = '';
    }

    return success;
  }

  /*
   ██████╗ ███████╗████████╗ █████╗ ██╗     ██╗
  ██╔════╝ ██╔════╝╚══██╔══╝██╔══██╗██║     ██║
  ██║  ███╗█████╗     ██║   ███████║██║     ██║
  ██║   ██║██╔══╝     ██║   ██╔══██║██║     ██║
  ╚██████╔╝███████╗   ██║   ██║  ██║███████╗███████╗
   ╚═════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚══════╝╚══════╝

  */
  async getAll(
    neighborhoodId: string
  ): Promise<void> {
    this.loading = true;
    const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/charges`;

    try {
      const token = await this.authService.getToken();
      const response = await new Promise<ServerResponse<{ charges: ICharge[] }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ charges: ICharge[] }>>(urlApi, {
            headers: {
              Authorization: token.toString(),
            },
          }).subscribe({
            next: (response) => resolve(new ServerResponse(response)),
            error: (error) => reject(error),
          });
        }
      );

      if (response.statusCode === 200) {

        console.log(response);
        

        this.charges = response.data?.charges.map((charge) => new Charge(charge)) || [];
      } else {
        this.toastService.show({
          header: 'Error',
          body: response.message,
          classname: 'bg-danger text-white',
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  /*
   ██████╗ ███████╗████████╗
  ██╔════╝ ██╔════╝╚══██╔══╝
  ██║  ███╗█████╗     ██║
  ██║   ██║██╔══╝     ██║
  ╚██████╔╝███████╗   ██║
   ╚═════╝ ╚══════╝   ╚═╝

  */
  async get(
    neighborhoodId: string,
    chargeId: string
  ): Promise<Charge | undefined> {
    const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/charges/${chargeId}`;
    this.spinnerService.loading = true;
    this.spinnerService.message = 'Obteniendo cargo...';

    try {
      const token = await this.authService.getToken();
      const response = await new Promise<ServerResponse<{charge: ICharge}>>((resolve, reject) => {
        this.http.get<IServerResponse<{charge: ICharge}>>(urlApi, {
          headers: { Authorization: token.toString() },
        }).subscribe({
          next: (response) => resolve(new ServerResponse(response)),
          error: (error) => reject(error),
        });
      });

      if (response.statusCode === 200 && response.data) {

        // console.log(response);
        
        return new Charge(response.data.charge);
      } else {
        this.toastService.show({
          header: 'Error',
          body: response.message,
          classname: 'bg-danger text-white',
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.spinnerService.loading = false;
      this.spinnerService.message = '';
    }

    return undefined;
  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
   ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async update(
    charge: Charge
  ): Promise<boolean> {
    let success = false;
    const urlApi = `${environment.urlApi}/neighborhoods/${charge.neighborhoodId}/charges/${charge.chargeId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Actualizando cargo...';

    try {
      const token = await this.authService.getToken();
      const response = await new Promise<ServerResponse>((resolve, reject) => {
        this.http.put<IServerResponse>(urlApi, charge, {
          headers: {
            Authorization: token.toString(),
            'Content-Type': 'application/json',
          },
        }).subscribe({
          next: (response) => resolve(new ServerResponse(response)),
          error: (error) => reject(error),
        });
      });

      if (response.statusCode === 200) {
        success = true;
        this.toastService.show({
          body: response.message,
          classname: 'bg-success text-white',
        });
        this.getAll(charge.neighborhoodId);
      } else {
        this.toastService.show({
          header: 'Error',
          body: response.message,
          classname: 'bg-danger text-white',
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.spinnerService.loading = false;
      this.spinnerService.message = '';
    }

    return success;
  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  async delete(charge: Charge): Promise<boolean> {
    let success = false;
    const urlApi = `${environment.urlApi}/neighborhoods/${charge.neighborhoodId}/charges/${charge.chargeId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Eliminando cargo...';

    try {
      const token = await this.authService.getToken();
      const response = await new Promise<ServerResponse>((resolve, reject) => {
        this.http.delete<IServerResponse>(urlApi, {
          headers: { Authorization: token.toString() },
        }).subscribe({
          next: (response) => resolve(new ServerResponse(response)),
          error: (error) => reject(error),
        });
      });

      if (response.statusCode === 200) {
        success = true;
        this.toastService.show({
          body: response.message,
          classname: 'bg-success text-white',
        });
        this.getAll(charge.neighborhoodId);
      } else {
        this.toastService.show({
          header: 'Error',
          body: response.message,
          classname: 'bg-danger text-white',
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.spinnerService.loading = false;
      this.spinnerService.message = '';
    }

    return success;
  }
  
}
