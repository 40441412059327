import { Component } from '@angular/core';
import { ChargeService } from '../../../services/charge.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NeighborhoodService } from '../../../services/neighborhood.service';

@Component({
  selector: 'app-payments-page',
  templateUrl: './payments-page.component.html',
  styleUrl: './payments-page.component.scss'
})
export class PaymentsPageComponent {

  public neighborhoodId: string = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    public chargeService: ChargeService,
    private modalService: NgbModal,
    public neighborhoodService: NeighborhoodService,
  ) {

    this.activatedRoute.params.subscribe(
      async params => {

        this.neighborhoodId = params['neighborhoodId'];          

        await this.chargeService.getAll(
          this.neighborhoodId!
        );
      }
    );

  }

  /*
   ██████╗ ██████╗ ███████╗███╗   ██╗
  ██╔═══██╗██╔══██╗██╔════╝████╗  ██║
  ██║   ██║██████╔╝█████╗  ██╔██╗ ██║
  ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║
  ╚██████╔╝██║     ███████╗██║ ╚████║
   ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝

  */
  async open() {

    const flag = confirm(`¿Estás seguro de crear un nuevo cobro? Se crearia el cobro de este mes en curso`);

    if(flag) {
      // await this.chargeService.create()
      const result = prompt(
        '¿Cuanto sera de mantenimiento es mes?',
        this.neighborhoodService.currentNeighborhood?.maintenanceFee.toString()
      );

      const amount = parseFloat(result!);
      if (!isNaN(amount)) {
        await this.chargeService.create(
          this.neighborhoodId!,
          amount
        );
      } else {
        alert('Por favor ingrese un número válido.');
      }
      
    }

  }

}
