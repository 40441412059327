import { Component } from '@angular/core';
import { ServiceService } from '../../../../services/service.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SpinnerService } from '../../../../services/spinner.service';
import { IService, Service } from '../../../../models/service.model';
import { ServiceModalComponent } from '../../../../components/service-modal/service-modal.component';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-services-page',
  templateUrl: './services-page.component.html',
  styleUrl: './services-page.component.scss'
})
export class ServicesPageComponent {

  public neighborhoodId?: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    public serviceService: ServiceService,
    private modalService: NgbModal,
    public spinnerService: SpinnerService,
  ) {

    this.activatedRoute.params.subscribe(
      async params => {

        this.neighborhoodId = params['neighborhoodId'];

        await this.serviceService.getAll(
          this.neighborhoodId!
        );
      }
    );

  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  async delete(
    service: Service
  ) {

    const flag = confirm(
      `¿Esta seguro que desea borrar el servicio ${service.service}?`
    );

    if(flag) {
      await this.serviceService.delete(
        service
      );
    }

  }

  /*
   ██████╗ ██████╗ ███████╗███╗   ██╗
  ██╔═══██╗██╔══██╗██╔════╝████╗  ██║
  ██║   ██║██████╔╝█████╗  ██╔██╗ ██║
  ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║
  ╚██████╔╝██║     ███████╗██║ ╚████║
   ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝

  */
  open(
    service?: Service,
  ) {
    const modalRef = this.modalService.open(
      ServiceModalComponent
    );

    modalRef.componentInstance.service = service;
    modalRef.componentInstance.neighborhoodId = this.neighborhoodId;

  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
   ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  /**
   * FUNCTION update
   * 
   * @param service 
   */
  async update(
    service: IService
  ) {

    console.log(service);
    

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Actualizando tipo de visita';

    await this.serviceService.update(
      service
    );

  }

}
