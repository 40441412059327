import { FileMedia, IFileMedia } from "./file-media.model";

export interface IPayment {
  paymentId: string;
  chargeId: string;
  houseFlatId: string;
  charge: string;
  fileId: string;
  status?: boolean;
  notes: string;
  uidCreatedBy?: string;
  uidUpdatedBy?: string;
  paidAt?: string;
  createdAt: string;
  updatedAt: string;
  file?: IFileMedia;
}

export class Payment {
  paymentId: string;
  chargeId: string;
  houseFlatId: string;
  charge: string;
  fileId: string;
  status?: boolean;
  notes: string;
  uidCreatedBy?: string;
  uidUpdatedBy?: string;
  paidAt?: Date;
  createdAt: Date;
  updatedAt: Date;
  file?: FileMedia;

  constructor(data: IPayment) {
    this.paymentId = data.paymentId;
    this.houseFlatId = data.houseFlatId;
    this.chargeId = data.chargeId;
    this.charge = data.charge;
    this.fileId = data.fileId;
    this.status = data.status;
    this.notes = data.notes;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.paidAt = data.paidAt ? new Date(data.paidAt) : undefined;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
    this.file = data.file 
      ? new FileMedia(data.file) 
      : undefined;
  }
}