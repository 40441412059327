import { Injectable } from '@angular/core';
import { AnnouncementType, IAnnouncementType } from '../models/announcement-type.model';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { SpinnerService } from './spinner.service';
import { ToastService } from './toast.service';
import { environment } from '../../environments/environment';
import { IServerResponse, ServerResponse } from '../models/server-response.model';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementTypeService {

  public loading: boolean = false;
  public announcementTypes: AnnouncementType[] = [];

  constructor(
    private http: HttpClient,
    public authService: AuthService,
    public spinnerService: SpinnerService,
    public toastService: ToastService
  ) { 
    this.getAll();
  }

  /*
   ██████╗ ███████╗████████╗ █████╗ ██╗     ██╗
  ██╔════╝ ██╔════╝╚══██╔══╝██╔══██╗██║     ██║
  ██║  ███╗█████╗     ██║   ███████║██║     ██║
  ██║   ██║██╔══╝     ██║   ██╔══██║██║     ██║
  ╚██████╔╝███████╗   ██║   ██║  ██║███████╗███████╗
   ╚═════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚══════╝╚══════╝

  */
  async getAll() {
    this.loading = true;
    const urlApi = `${environment.urlApi}/announcement-types`;

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse<{ announcementTypes: IAnnouncementType[] }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ announcementTypes: IAnnouncementType[] }>>(
            urlApi,
            { headers: { "Authorization": token.toString() } }
          ).subscribe({
            next: (response) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) resolve(new ServerResponse(error.error));
              else reject(error);
            }
          });
        }
      );

      if (response.statusCode === 200) {
        this.announcementTypes = response.data!.announcementTypes.map(
          type => new AnnouncementType(type)
        );
      } else {
        this.toastService.show({
          header: 'Error',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }

    } catch (error) {
      this.toastService.show({
        header: 'Error',
        body: `${error}`,
        classname: 'bg-danger text-white'
      });
    }

    this.loading = false;
  }
  
}
