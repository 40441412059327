import { Component, Input } from '@angular/core';
import { HouseFlat } from '../../models/house-flat.model';
import { StreetBuilding } from '../../models/street-building.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilService } from '../../services/util.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Resident } from '../../models/resident.model';
import { InvitationService } from '../../services/invitation.service';
import { ResidentService } from '../../services/resident.service';
import { IInvitation, Invitation } from '../../models/invitation.model';
import { AppUser } from '../../models/app-user.model';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-residents-modal',
  templateUrl: './residents-modal.component.html',
  styleUrl: './residents-modal.component.scss'
})
export class ResidentsModalComponent {

  @Input() houseFlat?: HouseFlat;
  @Input() streetBuilding?: StreetBuilding;

  public formInvitation: FormGroup;
  public submitted: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private invitationService: InvitationService,
    private residentService: ResidentService,
    public utilService: UtilService,
  ) {
    this.formInvitation = this.formBuilder.group({
      email: ['', Validators.required],
    });
  }

  get formControls() {
    return this.formInvitation.controls;
  }

  async createUser() {
    const appUser = await this.residentService.create(
      this.streetBuilding!.neighborhoodId,
      this.streetBuilding!.streetBuildingId,
      this.houseFlat!.houseFlatId
    );

    if(appUser) {
      this.downloadUser(appUser);
    }
  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗██╗███╗   ██╗██╗   ██╗██╗████████╗ █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝██║████╗  ██║██║   ██║██║╚══██╔══╝██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗  ██║██╔██╗ ██║██║   ██║██║   ██║   ███████║   ██║   ██║██║   ██║██╔██╗ ██║
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝  ██║██║╚██╗██║╚██╗ ██╔╝██║   ██║   ██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗██║██║ ╚████║ ╚████╔╝ ██║   ██║   ██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝╚═╝╚═╝  ╚═══╝  ╚═══╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝

  */
  async deleteInvitation(
    invitation: Invitation
  ) {

    await this.invitationService.delete(
      this.streetBuilding!.neighborhoodId,
      this.streetBuilding!.streetBuildingId,
      invitation
    );

    this.activeModal.close();

  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗██████╗ ███████╗███████╗██╗██████╗ ███████╗███╗   ██╗████████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝██╔══██╗██╔════╝██╔════╝██║██╔══██╗██╔════╝████╗  ██║╚══██╔══╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗  ██████╔╝█████╗  ███████╗██║██║  ██║█████╗  ██╔██╗ ██║   ██║
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝  ██╔══██╗██╔══╝  ╚════██║██║██║  ██║██╔══╝  ██║╚██╗██║   ██║
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗██║  ██║███████╗███████║██║██████╔╝███████╗██║ ╚████║   ██║
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝╚═╝  ╚═╝╚══════╝╚══════╝╚═╝╚═════╝ ╚══════╝╚═╝  ╚═══╝   ╚═╝

  */
  async deleteResident(
    resident: Resident
  ) {

    await this.residentService.delete(
      this.streetBuilding!.neighborhoodId,
      this.streetBuilding!.streetBuildingId,
      resident
    );

    this.activeModal.close();

  }

  /*
  ██████╗  ██████╗ ██╗    ██╗███╗   ██╗██╗      ██████╗  █████╗ ██████╗ ██╗   ██╗███████╗███████╗██████╗
  ██╔══██╗██╔═══██╗██║    ██║████╗  ██║██║     ██╔═══██╗██╔══██╗██╔══██╗██║   ██║██╔════╝██╔════╝██╔══██╗
  ██║  ██║██║   ██║██║ █╗ ██║██╔██╗ ██║██║     ██║   ██║███████║██║  ██║██║   ██║███████╗█████╗  ██████╔╝
  ██║  ██║██║   ██║██║███╗██║██║╚██╗██║██║     ██║   ██║██╔══██║██║  ██║██║   ██║╚════██║██╔══╝  ██╔══██╗
  ██████╔╝╚██████╔╝╚███╔███╔╝██║ ╚████║███████╗╚██████╔╝██║  ██║██████╔╝╚██████╔╝███████║███████╗██║  ██║
  ╚═════╝  ╚═════╝  ╚══╝╚══╝ ╚═╝  ╚═══╝╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝  ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝

  */
  downloadUser(
    appUser: AppUser
  ) {

    const headers = [
      'Usuario', 
      'Contraseña',
      'Calle',
      'Número'
    ];

    // Filas de datos
    const rows = [
      [
        appUser.username, 
        appUser.password,
        this.streetBuilding?.name,
        this.houseFlat?.number
      ]
    ];

    // Combinar encabezados y filas
    const data = [headers, ...rows];

    // Crear una hoja de Excel
    const worksheet = XLSX.utils.aoa_to_sheet(data);

    // Ajustar el ancho de las columnas automáticamente
    const columnWidths = headers.map(header => ({ wch: header.length + 5 })); // Ajusta +5 para un espacio adicional
    worksheet['!cols'] = columnWidths;

    // Crear un libro de trabajo y agregar la hoja
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Usuarios');

    // Exportar el archivo XLSX
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Crear un Blob para la descarga
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

    // Crear un enlace de descarga
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);

    // Asignar el nombre del archivo
    link.download = `user-${this.streetBuilding?.name}-${this.houseFlat?.number}.xlsx`;

    // Disparar la descarga
    link.click();

    this.activeModal.close();
  }

  /*
   ██████╗ ███╗   ██╗███████╗██╗   ██╗██████╗ ███╗   ███╗██╗████████╗
  ██╔═══██╗████╗  ██║██╔════╝██║   ██║██╔══██╗████╗ ████║██║╚══██╔══╝
  ██║   ██║██╔██╗ ██║███████╗██║   ██║██████╔╝██╔████╔██║██║   ██║
  ██║   ██║██║╚██╗██║╚════██║██║   ██║██╔══██╗██║╚██╔╝██║██║   ██║
  ╚██████╔╝██║ ╚████║███████║╚██████╔╝██████╔╝██║ ╚═╝ ██║██║   ██║
   ╚═════╝ ╚═╝  ╚═══╝╚══════╝ ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝   ╚═╝

  */
  async onSubmit() {
    this.submitted = true;
    if (this.formInvitation.invalid) {
      return;
    }

    await this.invitationService.create(
      this.streetBuilding!.neighborhoodId,
      this.streetBuilding!.streetBuildingId,
      this.houseFlat!.houseFlatId,
      this.formInvitation.value,
    )

  }

  /*
  ██████╗ ███████╗███████╗███████╗███╗   ██╗██████╗ ██╗███╗   ██╗██╗   ██╗██╗████████╗ █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
  ██╔══██╗██╔════╝██╔════╝██╔════╝████╗  ██║██╔══██╗██║████╗  ██║██║   ██║██║╚══██╔══╝██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
  ██████╔╝█████╗  ███████╗█████╗  ██╔██╗ ██║██║  ██║██║██╔██╗ ██║██║   ██║██║   ██║   ███████║   ██║   ██║██║   ██║██╔██╗ ██║
  ██╔══██╗██╔══╝  ╚════██║██╔══╝  ██║╚██╗██║██║  ██║██║██║╚██╗██║╚██╗ ██╔╝██║   ██║   ██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
  ██║  ██║███████╗███████║███████╗██║ ╚████║██████╔╝██║██║ ╚████║ ╚████╔╝ ██║   ██║   ██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
  ╚═╝  ╚═╝╚══════╝╚══════╝╚══════╝╚═╝  ╚═══╝╚═════╝ ╚═╝╚═╝  ╚═══╝  ╚═══╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝

  */
  resendInvitation(
    invitation: any
  ) {
    this.invitationService.create(
      this.streetBuilding!.neighborhoodId,
      this.streetBuilding!.streetBuildingId,
      this.houseFlat!.houseFlatId,
      invitation
    );
  }

  /*
  ██████╗ ███████╗███████╗████████╗ ██████╗ ██████╗ ███████╗██████╗  █████╗ ███████╗███████╗██╗    ██╗ ██████╗ ██████╗ ██████╗
  ██╔══██╗██╔════╝██╔════╝╚══██╔══╝██╔═══██╗██╔══██╗██╔════╝██╔══██╗██╔══██╗██╔════╝██╔════╝██║    ██║██╔═══██╗██╔══██╗██╔══██╗
  ██████╔╝█████╗  ███████╗   ██║   ██║   ██║██████╔╝█████╗  ██████╔╝███████║███████╗███████╗██║ █╗ ██║██║   ██║██████╔╝██║  ██║
  ██╔══██╗██╔══╝  ╚════██║   ██║   ██║   ██║██╔══██╗██╔══╝  ██╔═══╝ ██╔══██║╚════██║╚════██║██║███╗██║██║   ██║██╔══██╗██║  ██║
  ██║  ██║███████╗███████║   ██║   ╚██████╔╝██║  ██║███████╗██║     ██║  ██║███████║███████║╚███╔███╔╝╚██████╔╝██║  ██║██████╔╝
  ╚═╝  ╚═╝╚══════╝╚══════╝   ╚═╝    ╚═════╝ ╚═╝  ╚═╝╚══════╝╚═╝     ╚═╝  ╚═╝╚══════╝╚══════╝ ╚══╝╚══╝  ╚═════╝ ╚═╝  ╚═╝╚═════╝

  */
  async restorePassword(
    resident: Resident
  ) {

    const user = await this.residentService.restorePassword(
      this.streetBuilding!.neighborhoodId,
      this.streetBuilding!.streetBuildingId,
      resident
    );

    if(user) {
      if(user.username != "") {
        this.downloadUser(user);
      }
    }

  }

}
