import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VisitService } from '../../../services/visit.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-visits-page',
  templateUrl: './visits-page.component.html',
  styleUrl: './visits-page.component.scss'
})
export class VisitsPageComponent {

  public neighborhoodId?: string;
  
    constructor(
      private activatedRoute: ActivatedRoute,
      public visitService: VisitService,
      private modalService: NgbModal,
    ) {
  
      this.activatedRoute.params.subscribe(
        async params => {
  
          this.neighborhoodId = params['neighborhoodId'];
  
          await this.visitService.getAll(
            this.neighborhoodId!
          );
        }
      );
  
    }

}
