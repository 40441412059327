import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StreetBuildingService } from '../../../services/street-building.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
import { ResidentService } from '../../../services/resident.service';
import { SpinnerService } from '../../../services/spinner.service';
import { AuthService } from '../../../services/auth.service';


@Component({
  selector: 'app-houses-flats-page',
  templateUrl: './houses-flats-page.component.html',
  styleUrl: './houses-flats-page.component.scss'
})
export class HousesFlatsPageComponent {

  public neighborhoodId?: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    public authService: AuthService,
    private residentService: ResidentService,
    private spinnerService: SpinnerService,
    public streetBuildingService: StreetBuildingService,
    private modalService: NgbModal,
  ) {

    this.activatedRoute.params.subscribe(
      async params => {

        this.neighborhoodId = params['neighborhoodId'];

        await this.streetBuildingService.getAll(
          this.neighborhoodId!
        );
      }
    );

  }

  /*
  ██████╗  ██████╗ ██╗    ██╗███╗   ██╗██╗      ██████╗  █████╗ ██████╗ ██████╗ ███████╗██████╗  ██████╗ ██████╗ ████████╗
  ██╔══██╗██╔═══██╗██║    ██║████╗  ██║██║     ██╔═══██╗██╔══██╗██╔══██╗██╔══██╗██╔════╝██╔══██╗██╔═══██╗██╔══██╗╚══██╔══╝
  ██║  ██║██║   ██║██║ █╗ ██║██╔██╗ ██║██║     ██║   ██║███████║██║  ██║██████╔╝█████╗  ██████╔╝██║   ██║██████╔╝   ██║
  ██║  ██║██║   ██║██║███╗██║██║╚██╗██║██║     ██║   ██║██╔══██║██║  ██║██╔══██╗██╔══╝  ██╔═══╝ ██║   ██║██╔══██╗   ██║
  ██████╔╝╚██████╔╝╚███╔███╔╝██║ ╚████║███████╗╚██████╔╝██║  ██║██████╔╝██║  ██║███████╗██║     ╚██████╔╝██║  ██║   ██║
  ╚═════╝  ╚═════╝  ╚══╝╚══╝ ╚═╝  ╚═══╝╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝ ╚═╝  ╚═╝╚══════╝╚═╝      ╚═════╝ ╚═╝  ╚═╝   ╚═╝

  */
  downloadReport() {

    const data: any[] = [];
    const mergeRanges: any[] = [];

    let currentIndex = 0;

    this.streetBuildingService.streetsBuildings.forEach(
      (streetBuilding, index) => {
        data.push([
          streetBuilding.name
        ]);

        mergeRanges.push({
          s: { r: currentIndex, c: 0 }, // Celda de inicio (row, column)
          e: { r: currentIndex, c: 5 }  // Celda de fin (row, column) - 6 columnas combinadas
        });

        currentIndex++;
        data.push([
          'NÚMERO',
          'NOMBRE',
          'TELÉFONO',
          'ESTATUS',
          'NOTAS',
          'EXTRA'
        ]);

        streetBuilding.housesFlats.forEach(
          (houseFlat, jindex) => {
            currentIndex++;
            data.push([
              houseFlat.number,
              houseFlat.fullName,
              houseFlat.phone,
              houseFlat.status ? 'Activo' : 'Moroso',
              houseFlat.notes,
              ''
            ]);
          }
        );

        currentIndex++;
      }
    );

    console.log(data);
    

    // Crear una hoja de Excel
    const worksheet = XLSX.utils.aoa_to_sheet(data);

    worksheet['!merges'] = mergeRanges;

    // Calcular el ancho máximo de cada columna
    const columnWidths = data[1].map((_: any, colIndex: string | number) => {
      const maxLength = data.reduce((max, row) => {
        const cell = row[colIndex];
        const cellLength = cell ? cell.toString().length : 0;
        return Math.max(max, cellLength);
      }, 0);
      return { wch: maxLength + 2 }; // Ajuste adicional para margen
    });

    // Asignar los anchos de columna al worksheet
    worksheet['!cols'] = columnWidths;

    // Crear un libro de trabajo y agregar la hoja
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Residentes');

    // Exportar el archivo XLSX
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    // Crear un Blob para la descarga
    const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

    // Crear un enlace de descarga
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);

    // Asignar el nombre del archivo
    link.download = `reporte-fraccionamiento.xlsx`;

    // Disparar la descarga
    link.click();
  }

  /*
   ██████╗ ███████╗███╗   ██╗███████╗██████╗  █████╗ ████████╗███████╗██╗   ██╗███████╗███████╗██████╗ ███████╗
  ██╔════╝ ██╔════╝████╗  ██║██╔════╝██╔══██╗██╔══██╗╚══██╔══╝██╔════╝██║   ██║██╔════╝██╔════╝██╔══██╗██╔════╝
  ██║  ███╗█████╗  ██╔██╗ ██║█████╗  ██████╔╝███████║   ██║   █████╗  ██║   ██║███████╗█████╗  ██████╔╝███████╗
  ██║   ██║██╔══╝  ██║╚██╗██║██╔══╝  ██╔══██╗██╔══██║   ██║   ██╔══╝  ██║   ██║╚════██║██╔══╝  ██╔══██╗╚════██║
  ╚██████╔╝███████╗██║ ╚████║███████╗██║  ██║██║  ██║   ██║   ███████╗╚██████╔╝███████║███████╗██║  ██║███████║
   ╚═════╝ ╚══════╝╚═╝  ╚═══╝╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝   ╚══════╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚══════╝

  */
  async generateUsers() {

    const newPosibleUsers: {
      neighborhoodId: string;
      streetBuildingId: string;
      streetBuildingName: string;
      houseFlatId: string;
      houseFlatNumber: string;
    }[] = [];

    let numberOfHouses = 0;

    this.streetBuildingService.streetsBuildings.forEach(
      streetBuilding => {
        streetBuilding.housesFlats.forEach(
          houseFlat => {

            if(houseFlat.residents.length > 0) {

              const flag = houseFlat.residents.find(
                resident => resident.resident!.username != ""
              );

              if(!flag) {
                newPosibleUsers.push({
                  neighborhoodId: streetBuilding.neighborhoodId,
                  streetBuildingId: streetBuilding.streetBuildingId,
                  streetBuildingName: streetBuilding.name,
                  houseFlatId: houseFlat.houseFlatId,
                  houseFlatNumber: houseFlat.number,
                });
              }

            } else {

              newPosibleUsers.push({
                neighborhoodId: streetBuilding.neighborhoodId,
                streetBuildingId: streetBuilding.streetBuildingId,
                streetBuildingName: streetBuilding.name,
                houseFlatId: houseFlat.houseFlatId,
                houseFlatNumber: houseFlat.number,
              });

            }

            numberOfHouses++;
          }
        );
      }
    );

    console.log(newPosibleUsers);

    const flagConfirmGenerate = confirm(`Se generarán ${newPosibleUsers.length} usuarios de un total de ${numberOfHouses} casas. ¿Desea continuar?`);

    if(flagConfirmGenerate) {

      this.spinnerService.loading = true;
      this.spinnerService.message = 'Generando usuarios...';

      const users: {
        username: string;
        password: string;
        streetBuildingName: string;
        houseFlatNumber: string;
      }[] = [];

      for(const newUser of newPosibleUsers) {

        const appUser = await this.residentService.create(
          newUser.neighborhoodId,
          newUser.streetBuildingId,
          newUser.houseFlatId
        );

        if(appUser) {
          users.push({
            username: appUser.username,
            password: appUser.password!,
            streetBuildingName: newUser.streetBuildingName,
            houseFlatNumber: newUser.houseFlatNumber
          });
        }

      }

      const headers = [
        'Usuario', 
        'Contraseña',
        'Calle',
        'Número'
      ];

      const rows: any[] = [];

      users.forEach(
        user => {
          rows.push([
            user.username,
            user.password,
            user.streetBuildingName,
            user.houseFlatNumber
          ]);
        }
      );

      const data = [headers, ...rows];

      // Crear una hoja de Excel
      const worksheet = XLSX.utils.aoa_to_sheet(data);

      // Ajustar el ancho de las columnas automáticamente
      const columnWidths = headers.map(header => ({ wch: header.length + 5 })); // Ajusta +5 para un espacio adicional
      worksheet['!cols'] = columnWidths;

      // Crear un libro de trabajo y agregar la hoja
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Usuarios');

      // Exportar el archivo XLSX
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

      // Crear un Blob para la descarga
      const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });

      // Crear un enlace de descarga
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);

      // Asignar el nombre del archivo
      link.download = `users-${this.neighborhoodId}.xlsx`;

      this.spinnerService.loading = false;
      this.spinnerService.message = '';

      // Disparar la descarga
      link.click();

    }

  }

}
