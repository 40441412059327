import { Component } from '@angular/core';
import { NeighborhoodService } from '../../../../services/neighborhood.service';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-general-page',
  templateUrl: './general-page.component.html',
  styleUrl: './general-page.component.scss'
})
export class GeneralPageComponent {

  constructor(
    public authService: AuthService,
    public neighborhoodService: NeighborhoodService
  ) {

  }

  update() {

    this.neighborhoodService.update();
  }

}
