<div class="col-12 bg-white rounded px-3 py-3 mb-4">
  <div class="d-flex">

    <div class="flex-grow-1">
      <div class="row">
        <div class="col-12 col-md-6 mb-3">
          <div class="row">

            <div class="col-12 text-secondary mb-1">
              <small><b>Banco</b></small>
              <button
                (click)="utilService.copyToClipboard(bankAccount?.bankName!)"
                class="btn btn-sm text-secondaryms-2">
                <small><i class="material-icons">content_copy</i></small>
              </button>
            </div>
            <div class="col-12">
              {{ bankAccount?.bankName }}
            </div>

          </div>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <div class="row">

            <div class="col-12 text-secondary mb-1">
              <small><b>Titular</b></small>
              <button
                (click)="utilService.copyToClipboard(bankAccount?.fullName!)"
                class="btn btn-sm text-secondaryms-2">
                <small><i class="material-icons">content_copy</i></small>
              </button>
            </div>
            <div class="col-12">
              {{ bankAccount?.fullName }}
            </div>

          </div>
        </div>

        <div class="col-12">
          <div class="row">

            <div class="col-12 text-secondary mb-1">
              <small><b>{{ bankAccount?.bankAccountType?.bankAccountType }}</b></small>
              <button
                (click)="utilService.copyToClipboard(bankAccount?.accountNumber!)"
                class="btn btn-sm text-secondaryms-2">
                <small><i class="material-icons">content_copy</i></small>
              </button>
            </div>
            <div class="col-12">
              {{ bankAccount?.accountNumber }}
            </div>

          </div>
        </div>

      </div>
    </div>

    <div class="">
      <button
        (click)="open()"
        class="btn btn-sm btn-light rounded-circle me-2 my-1">
        <i class="material-icons mt-1">edit</i>
      </button>
      <button
        (click)="delete()"
        class="btn btn-sm btn-danger rounded-circle me-2 my-1">
        <i class="material-icons mt-1">delete</i>
      </button>
    </div>

  </div>
</div>