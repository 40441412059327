<div class="container-fluid py-3">
  <div class="row">
    <div class="col-12">
      <p-table #dt
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [filterDelay]="0"
        [loading]="announcementService.loading"
        [paginator]="true"
        [rows]="10"
        [rowHover]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        [showCurrentPageReport]="true" 
        [value]="announcementService.announcements"
        pDroppable="row">
        
        <ng-template pTemplate="caption">
          <div class="container-fluid">
            <div class="row">
              <div class="col-4 d-flex align-items-center">
                Anuncios
              </div>
              <div class="col-8 text-end">
                <button (click)="create()" class="btn btn-success rounded-circle me-2">
                  <i class="material-icons mt-1">add</i>
                </button>
                <button (click)="announcementService.getAll(neighborhoodId!)" class="btn btn-primary rounded-circle me-2">
                  <i class="material-icons mt-1">refresh</i>
                </button>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="announcementId">
              ID
              <p-sortIcon field="announcementId"></p-sortIcon>
              <p-columnFilter type="text" field="announcementId" display="menu"></p-columnFilter>
            </th>

            <th pSortableColumn="announcementType">
              Tipo
              <p-sortIcon field="announcementType"></p-sortIcon>
              <p-columnFilter type="text" field="announcementType" display="menu"></p-columnFilter>
            </th>

            <th pSortableColumn="title">
              Título
              <p-sortIcon field="title"></p-sortIcon>
              <p-columnFilter type="text" field="title" display="menu"></p-columnFilter>
            </th>

            <th>Acciones</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-announcement>
          <tr class="p-selectable-row">
            <td>
              <span class="p-column-title">ID</span>
              {{announcement.announcementId}}
            </td>

            <td>
              <span class="p-column-title">Tipo</span>
              {{announcement.announcementType}}
            </td>

            <td>
              <span class="p-column-title">Título</span>
              {{announcement.title}}
            </td>

            <td>
              <button (click)="view(announcement)" class="btn btn-sm btn-light rounded-circle me-2">
                <i class="material-icons mt-1">edit</i>
              </button>
              <button (click)="delete(announcement)" class="btn btn-sm btn-danger rounded-circle">
                <i class="material-icons mt-1">delete</i>
              </button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>
