import { Component, Input } from '@angular/core';
import { HouseFlat } from '../../models/house-flat.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StreetBuilding } from '../../models/street-building.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PaymentService } from '../../services/payment.service';

@Component({
  selector: 'app-payment-modal',
  templateUrl: './payment-modal.component.html',
  styleUrl: './payment-modal.component.scss'
})
export class PaymentModalComponent {

  @Input() streetBuilding?: StreetBuilding;
  @Input() houseFlat?: HouseFlat;
  @Input() neighborhoodId: string = '';

  public formPayment: FormGroup;
  public submitted: boolean = false;

  public options: any[] = [
    { value: null, label: 'Sin pago' },
    { value: false, label: 'Validando' },
    { value: true, label: 'Aprobado' },
  ]

  constructor(
    public activeModal: NgbActiveModal,
    public paymentService: PaymentService,
    private formBuilder: FormBuilder,
  ) {

    this.formPayment = this.formBuilder.group({
      charge: ['', Validators.required],
      status: [null],
      notes: [''],
    });

  }

  ngOnInit() {

    console.log(this.houseFlat?.payment);
    

    this.formPayment.patchValue({
      charge: this.houseFlat?.payment?.charge,
      status: this.houseFlat?.payment?.status,
      notes: this.houseFlat?.payment?.notes,
    });
  }

  get formControls() {
    return this.formPayment.controls;
  } 

  /*
   ██████╗ ███╗   ██╗███████╗██╗   ██╗██████╗ ███╗   ███╗██╗████████╗
  ██╔═══██╗████╗  ██║██╔════╝██║   ██║██╔══██╗████╗ ████║██║╚══██╔══╝
  ██║   ██║██╔██╗ ██║███████╗██║   ██║██████╔╝██╔████╔██║██║   ██║
  ██║   ██║██║╚██╗██║╚════██║██║   ██║██╔══██╗██║╚██╔╝██║██║   ██║
  ╚██████╔╝██║ ╚████║███████║╚██████╔╝██████╔╝██║ ╚═╝ ██║██║   ██║
   ╚═════╝ ╚═╝  ╚═══╝╚══════╝ ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝   ╚═╝

  */
  async onSubmit() {
    this.submitted = true;
    if (this.formPayment.invalid) {
      return;
    }

    const flag = await this.paymentService.update(
      this.neighborhoodId,
      this.houseFlat!,
      this.formPayment.value
    );


    if(flag) {
      this.activeModal.close();
    }
  }

}
