import { Component, Input } from '@angular/core';
import { BankAccount } from '../../models/bank-account.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DataFormModel } from '../../models/data-form-model.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BankAccountService } from '../../services/bank-account.service';
import { BankAccountTypeService } from '../../services/bank-account-type.service';
import { UtilService } from '../../services/util.service';

@Component({
  selector: 'app-bank-account-modal',
  templateUrl: './bank-account-modal.component.html',
  styleUrl: './bank-account-modal.component.scss'
})
export class BankAccountModalComponent {

  @Input() neighborhoodId: string = '';
  @Input() bankAccount?: BankAccount;

  public dataForm: DataFormModel = {
    bankAccountTypeId: {
      value: null,
      type: 'select',
      idSelect: 'bankAccountTypeId',
      labelSelect: 'bankAccountType',
      data: [],
      label: 'Tipo de cuenta',
      placeholder: 'Seleccione el tipo de cuenta',
      requiredMessage: 'Por favor, seleccione el tipo de cuenta',
      required: true,
      validators: [Validators.required]
    },
    bankName: {
      value: '',
      type: 'text',
      label: 'Nombre del banco',
      requiredMessage: 'Por favor, ingrese uel nombre del banco',
      required: true,
      validators: [Validators.required]
    },
    fullName: {
      value: '',
      type: 'text',
      label: 'Nombre completo del titular',
      requiredMessage: 'Por favor, ingrese el nombre completo del titular',
      required: true,
      validators: [Validators.required]
    },
    accountNumber: {
      value: '',
      type: 'text',
      label: 'Número de cuenta',
      requiredMessage: 'Por favor, ingrese el número de cuenta',
      required: true,
      validators: [Validators.required]
    },
  };

  public formBankAccount!: FormGroup;
  public submitted: boolean = false;
  public loading: boolean = false;

  constructor(
    public activeModal: NgbActiveModal,
    public bankAccountService: BankAccountService,
    public bankAccountTypeService: BankAccountTypeService,
    public utilService: UtilService,
  ) {

  }

  ngOnInit(): void {
    this.loadData();
  }

  get f() {
    return this.formBankAccount.controls;
  } 

  /*
   ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
   ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
   async create() {

    const flag = await this.bankAccountService.create(
      this.neighborhoodId!,
      this.formBankAccount.value,
    );

    if(flag) {
      for(let key of Object.keys(this.dataForm)) {
        this.dataForm[key].value = '';
      }

      this.activeModal.close();
    }

  }

  /*
  ██╗      ██████╗  █████╗ ██████╗ ██████╗  █████╗ ████████╗ █████╗
  ██║     ██╔═══██╗██╔══██╗██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔══██╗
  ██║     ██║   ██║███████║██║  ██║██║  ██║███████║   ██║   ███████║
  ██║     ██║   ██║██╔══██║██║  ██║██║  ██║██╔══██║   ██║   ██╔══██║
  ███████╗╚██████╔╝██║  ██║██████╔╝██████╔╝██║  ██║   ██║   ██║  ██║
  ╚══════╝ ╚═════╝ ╚═╝  ╚═╝╚═════╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝  ╚═╝

  */
  async loadData() {
    this.loading = true;

    this.dataForm['bankAccountTypeId'].data = await this.bankAccountTypeService.getAll();

    if(this.bankAccount) {

      this.dataForm = {
        ...this.dataForm,
      }

      for(let key of Object.keys(this.dataForm)) {

        if (key in this.bankAccount!) {
          const standardKey = key as keyof typeof this.bankAccount;
      
          // Ahora puedes acceder a `this.machine[machineKey]` sin errores de tipo.
          this.dataForm[key].value = this.bankAccount[standardKey]!;
        }
      } 
    }
    
    this.formBankAccount = this.utilService.buildForm(this.dataForm);

    this.loading = false;
  }

  /*
   ██████╗ ███╗   ██╗███████╗██╗   ██╗██████╗ ███╗   ███╗██╗████████╗
  ██╔═══██╗████╗  ██║██╔════╝██║   ██║██╔══██╗████╗ ████║██║╚══██╔══╝
  ██║   ██║██╔██╗ ██║███████╗██║   ██║██████╔╝██╔████╔██║██║   ██║
  ██║   ██║██║╚██╗██║╚════██║██║   ██║██╔══██╗██║╚██╔╝██║██║   ██║
  ╚██████╔╝██║ ╚████║███████║╚██████╔╝██████╔╝██║ ╚═╝ ██║██║   ██║
   ╚═════╝ ╚═╝  ╚═══╝╚══════╝ ╚═════╝ ╚═════╝ ╚═╝     ╚═╝╚═╝   ╚═╝

  */
  async onSubmit() {

    console.log(this.formBankAccount.value);
    
    this.submitted = true;

    if(this.formBankAccount.invalid) {
      return;
    }

    if(this.bankAccount) {

      await this.update();
    } else {
      await this.create();
    }
  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
   ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async update() {

    this.formBankAccount.value.neighborhoodId = this.bankAccount!.neighborhoodId;
    this.formBankAccount.value.bankAccountId = this.bankAccount!.bankAccountId;

    const flag = await this.bankAccountService.update(
      this.formBankAccount.value
    );

    if(flag) {

      for(let key of Object.keys(this.dataForm)) {
        this.dataForm[key].value = '';
      }

      this.activeModal.close();
    }
  }

}
