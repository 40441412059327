import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChargeService } from '../../../services/charge.service';
import { Charge } from '../../../models/charge.model';
import { HouseFlat } from '../../../models/house-flat.model';
import { HouseFlatModalComponent } from '../../../components/house-flat-modal/house-flat-modal.component';
import { HouseFlatService } from '../../../services/house-flat.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentModalComponent } from '../../../components/payment-modal/payment-modal.component';
import { StreetBuilding } from '../../../models/street-building.model';

@Component({
  selector: 'app-payment-page',
  templateUrl: './payment-page.component.html',
  styleUrl: './payment-page.component.scss'
})
export class PaymentPageComponent {

  public neighborhoodId: string = '';
  public chargeId: string = '';
  public charge?: Charge;

  constructor(
    private activatedRoute: ActivatedRoute,
    public chargeService: ChargeService,
    public houseFlatService: HouseFlatService,
    private modalService: NgbModal,
  ) {

    this.activatedRoute.params.subscribe(
      async params => {

        // console.log(params);

        this.neighborhoodId = params['neighborhoodId'];          
        this.chargeId = params['paymentId'];          

        this.charge = await this.chargeService.get(
          this.neighborhoodId!,
          this.chargeId!
        );
      }
    );

  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
   ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async update(
    houseFlat: HouseFlat
  ) {
    const flag = await this.houseFlatService.update(
      this.neighborhoodId,
      houseFlat
    );

    if(!flag) {
      houseFlat.status = !houseFlat.status;
    }
  }

  /*
    ██████╗ ██████╗ ███████╗███╗   ██╗██╗  ██╗ ██████╗ ██╗   ██╗███████╗███████╗███████╗██╗      █████╗ ████████╗
  ██╔═══██╗██╔══██╗██╔════╝████╗  ██║██║  ██║██╔═══██╗██║   ██║██╔════╝██╔════╝██╔════╝██║     ██╔══██╗╚══██╔══╝
  ██║   ██║██████╔╝█████╗  ██╔██╗ ██║███████║██║   ██║██║   ██║███████╗█████╗  █████╗  ██║     ███████║   ██║
  ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║██╔══██║██║   ██║██║   ██║╚════██║██╔══╝  ██╔══╝  ██║     ██╔══██║   ██║
  ╚██████╔╝██║     ███████╗██║ ╚████║██║  ██║╚██████╔╝╚██████╔╝███████║███████╗██║     ███████╗██║  ██║   ██║
    ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚══════╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝

  */
  openHouseFlat(
    houseFlat: HouseFlat,
  ) {
    const modalRef = this.modalService.open(
      HouseFlatModalComponent
    );

    modalRef.componentInstance.houseFlat = houseFlat;
    modalRef.componentInstance.neighborhoodId = this.neighborhoodId;
    modalRef.componentInstance.streetBuildingId = houseFlat.streetBuildingId;

  }

  /*
   ██████╗ ██████╗ ███████╗███╗   ██╗██████╗  █████╗ ██╗   ██╗███╗   ███╗███████╗███╗   ██╗████████╗
  ██╔═══██╗██╔══██╗██╔════╝████╗  ██║██╔══██╗██╔══██╗╚██╗ ██╔╝████╗ ████║██╔════╝████╗  ██║╚══██╔══╝
  ██║   ██║██████╔╝█████╗  ██╔██╗ ██║██████╔╝███████║ ╚████╔╝ ██╔████╔██║█████╗  ██╔██╗ ██║   ██║
  ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║██╔═══╝ ██╔══██║  ╚██╔╝  ██║╚██╔╝██║██╔══╝  ██║╚██╗██║   ██║
  ╚██████╔╝██║     ███████╗██║ ╚████║██║     ██║  ██║   ██║   ██║ ╚═╝ ██║███████╗██║ ╚████║   ██║
   ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝╚═╝     ╚═╝  ╚═╝   ╚═╝   ╚═╝     ╚═╝╚══════╝╚═╝  ╚═══╝   ╚═╝

  */
  openPayment(
    streetBuilding: StreetBuilding,
    houseFlat: HouseFlat
  ) {

    const modalRef = this.modalService.open(
      PaymentModalComponent
    );

    modalRef.componentInstance.streetBuilding = streetBuilding;
    modalRef.componentInstance.houseFlat = houseFlat;
    modalRef.componentInstance.neighborhoodId = this.neighborhoodId;

    modalRef.closed.subscribe(
      async () => {
    
        this.charge = await this.chargeService.get(
          this.neighborhoodId!,
          this.chargeId!
        );

      }
    )
    
  }

}
