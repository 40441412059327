import { Injectable } from '@angular/core';
import { EmergencyNumber, IEmergencyNumber } from '../models/emergency-number.model';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from './spinner.service';
import { ToastService } from './toast.service';
import { environment } from '../../environments/environment';
import { IServerResponse, ServerResponse } from '../models/server-response.model';

@Injectable({
  providedIn: 'root'
})
export class EmergencyNumberService {

  public loading: boolean = false;
  public emergencyNumbers: EmergencyNumber[] = [];

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    public spinnerService: SpinnerService,
    public toastService: ToastService
  ) { }

  /*
     ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
    ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
    ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
    ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
    ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
     ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝
  
    */
     async create(
      neighborhoodId: string,
      emergencyNumber: IEmergencyNumber
    ): Promise<boolean> {
  
      let flag = false;
  
      const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/emergency-numbers`;
      this.spinnerService.loading = true;
      this.spinnerService.message = 'Registrando número de emergencia';
  
      try {
  
        const token = await this.authService.getToken();
  
        const response = await new Promise<ServerResponse>(
          (resolve, reject) => {
            this.http.post<IServerResponse>(
              urlApi,
              JSON.stringify(emergencyNumber),
              {
                headers: {
                  "content-type": "application/json",
                  'Authorization': token.toString()
                }
              }
            ).subscribe({
              next: (
                  response: IServerResponse
                ) => resolve(
                  new ServerResponse(response)
                ),
              error: (error) => {
                if (error.error) {
                  resolve(new ServerResponse(error.error));
                } else {
                  reject(error);
                  this.toastService.show(
                    {
                      header: 'Error de servidor',
                      body: `${error}`,
                      classname: 'bg-danger text-white'
                    }
                  );
                }
              },
            });
          }
        );
  
        console.log(response);
  
        if(response.statusCode === 201) {
          flag = true;
  
          this.toastService.show(
            {
              body: `${response.message}`,
              classname: 'bg-success text-white'
            }
          );
  
          this.getAll(
            neighborhoodId,
          );
  
        } else {
  
          this.toastService.show(
            {
              header: 'Error de servidor',
              body: `${response.message}`,
              classname: 'bg-danger text-white'
            }
          );
  
        }
  
      } catch(error) {
        console.log(error);
  
        this.toastService.show(
          {
            header: 'Error',
            body: `${error}`,
            classname: 'bg-danger text-white'
          }
        );
      }
  
      this.spinnerService.loading = false;
      this.spinnerService.message = '';
  
      return flag;
  
    }
  
    /*
    ██████╗ ███████╗██╗     ███████╗████████╗███████╗
    ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
    ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
    ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
    ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
    ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝
  
    */
    async delete(
      emergencyNumber: EmergencyNumber
    ): Promise<boolean> {
  
      let flag = false;
  
      const urlApi = `${environment.urlApi}/neighborhoods/${emergencyNumber.neighborhoodId}/emergency-numbers/${emergencyNumber.emergencyNumberId}`;
  
      this.spinnerService.loading = true;
      this.spinnerService.message = 'Eliminando número de emergencia';
  
      try {
  
        const token = await this.authService.getToken();
  
        const response = await new Promise<ServerResponse>(
          (resolve, reject) => {
            this.http.delete<IServerResponse>(
              urlApi,
              {
                headers: {
                  'Authorization': token.toString()
                }
              }
            ).subscribe({
              next: (
                  response: IServerResponse
                ) => resolve(
                  new ServerResponse(response)
                ),
              error: (error) => {
                if (error.error) {
                  resolve(new ServerResponse(error.error));
                } else {
                  reject(error);
                  this.toastService.show(
                    {
                      header: 'Error de servidor',
                      body: `${error}`,
                      classname: 'bg-danger text-white'
                    }
                  );
                }
              },
            });
          }
        );
  
        console.log(response);
  
        if(response.statusCode === 200) {
          flag = true;
  
          this.toastService.show(
            {
              body: `${response.message}`,
              classname: 'bg-success text-white'
            }
          );
  
          this.getAll(
            emergencyNumber.neighborhoodId,
          );
  
        } else {
  
          this.toastService.show(
            {
              header: 'Error de servidor',
              body: `${response.message}`,
              classname: 'bg-danger text-white'
            }
          );
  
        }
  
      } catch(error) {
        console.log(error);
  
        this.toastService.show(
          {
            header: 'Error',
            body: `${error}`,
            classname: 'bg-danger text-white'
          }
        );
      }
  
      this.spinnerService.loading = false;
      this.spinnerService.message = '';
    
      return flag;
  
    }
  
    /*
     ██████╗ ███████╗████████╗ █████╗ ██╗     ██╗
    ██╔════╝ ██╔════╝╚══██╔══╝██╔══██╗██║     ██║
    ██║  ███╗█████╗     ██║   ███████║██║     ██║
    ██║   ██║██╔══╝     ██║   ██╔══██║██║     ██║
    ╚██████╔╝███████╗   ██║   ██║  ██║███████╗███████╗
     ╚═════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚══════╝╚══════╝
  
    */
    /**
     * FUNCTION getAll
     */
    async getAll(
      neighborhoodId: string,
    ) {
  
      this.loading = true;
      this.emergencyNumbers = [];
  
      const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/emergency-numbers`;
      try {
  
        const token = await this.authService.getToken();
  
        const response = await new Promise<ServerResponse<{ emergencyNumbers: IEmergencyNumber[] }>>(
          (resolve, reject) => {
            this.http.get<IServerResponse<{ emergencyNumbers: IEmergencyNumber[] }>>(
              urlApi,
              {
                headers: {
                  "content-type": "application/json",
                  "Authorization": token.toString()
                }
              }
            ).subscribe({
              next: (
                  response: IServerResponse<{ emergencyNumbers: IEmergencyNumber[] }>
                ) => resolve(
                  new ServerResponse<{ emergencyNumbers: IEmergencyNumber[] }>(response)
                ),
              error: (error) => {
                if (error.error) {
                  resolve(new ServerResponse(error.error));
                } else {
                  reject(error);
                }
              },
            });
          }
        );
  
        console.log(response);
  
        if(response.statusCode === 200) {
          this.emergencyNumbers = response.data!.emergencyNumbers.map(
            emergencyNumber => new EmergencyNumber(emergencyNumber)
          );
        } else {
  
          this.toastService.show(
            {
              header: 'Error de servidor',
              body: `${response.message}`,
              classname: 'bg-danger text-white'
            }
          );
  
        }
  
      } catch(error) {
        console.log(error);
  
        this.toastService.show(
          {
            header: 'Error',
            body: `${error}`,
            classname: 'bg-danger text-white'
          }
        );
        
      }
  
      this.loading = false;
  
    }
  
    /*
    ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
    ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
    ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
    ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
    ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
     ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝
  
    */
    async update(
      emergencyNumber: EmergencyNumber
    ): Promise<boolean> {
  
      let flag = false;
  
      const urlApi = `${environment.urlApi}/neighborhoods/${emergencyNumber.neighborhoodId}/emergency-numbers/${emergencyNumber.emergencyNumberId}`;
  
      this.spinnerService.loading = true;
      this.spinnerService.message = 'Actualizando número de emergencia';
  
      try {
  
        const token = await this.authService.getToken();
  
        const response = await new Promise<ServerResponse>(
          (resolve, reject) => {
            this.http.put<IServerResponse>(
              urlApi,
              JSON.stringify(emergencyNumber),
              {
                headers: {
                  "content-type": "application/json",
                  'Authorization': token.toString()
                }
              }
            ).subscribe({
              next: (
                  response: IServerResponse
                ) => resolve(
                  new ServerResponse(response)
                ),
              error: (error) => {
                if (error.error) {
                  resolve(new ServerResponse(error.error));
                } else {
                  reject(error);
                  this.toastService.show(
                    {
                      header: 'Error de servidor',
                      body: `${error}`,
                      classname: 'bg-danger text-white'
                    }
                  );
                }
              },
            });
          }
        );
  
        console.log(response);
  
        if(response.statusCode === 200) {
          flag = true;
  
          this.toastService.show(
            {
              body: `${response.message}`,
              classname: 'bg-success text-white'
            }
          );
  
          this.getAll(
            emergencyNumber.neighborhoodId,
          );
  
        } else {
  
          this.toastService.show(
            {
              header: 'Error de servidor',
              body: `${response.message}`,
              classname: 'bg-danger text-white'
            }
          );
  
        }
  
      } catch(error) {
        console.log(error);
  
        this.toastService.show(
          {
            header: 'Error',
            body: `${error}`,
            classname: 'bg-danger text-white'
          }
        );
      }
  
      this.spinnerService.loading = false;
      this.spinnerService.message = '';
  
      return flag;
  
    }
}
