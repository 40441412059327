import { Component, Input } from '@angular/core';
import { StreetBuilding } from '../../models/street-building.model';
import { HouseFlatService } from '../../services/house-flat.service';
import { StreetBuildingService } from '../../services/street-building.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HouseFlat } from '../../models/house-flat.model';
import { ResidentsModalComponent } from '../residents-modal/residents-modal.component';
import { HouseFlatModalComponent } from '../house-flat-modal/house-flat-modal.component';

@Component({
  selector: 'app-street-building-item-2',
  templateUrl: './street-building-item-2.component.html',
  styleUrl: './street-building-item-2.component.scss'
})
export class StreetBuildingItem2Component {

  @Input() streetBuilding?: StreetBuilding;

  constructor(
    public houseFlatService: HouseFlatService,
    public streetBuildingService: StreetBuildingService,
    private modalService: NgbModal,
  ) {

  }

  /*
   ██████╗ ██████╗ ███████╗███╗   ██╗
  ██╔═══██╗██╔══██╗██╔════╝████╗  ██║
  ██║   ██║██████╔╝█████╗  ██╔██╗ ██║
  ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║
  ╚██████╔╝██║     ███████╗██║ ╚████║
   ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝

  */
  open(
    houseFlat?: HouseFlat,
  ) {
    const modalRef = this.modalService.open(
      ResidentsModalComponent,
      {
        size: 'lg'
      }
    );

    modalRef.componentInstance.houseFlat = houseFlat;
    modalRef.componentInstance.streetBuilding = this.streetBuilding;

  }

  async update(
    houseFlat: HouseFlat
  ) {
    const flag = await this.houseFlatService.update(
      this.streetBuilding!.neighborhoodId,
      houseFlat
    );

    if(!flag) {
      houseFlat.status = !houseFlat.status;
    }
  }

  /*
     ██████╗ ██████╗ ███████╗███╗   ██╗██╗  ██╗ ██████╗ ██╗   ██╗███████╗███████╗███████╗██╗      █████╗ ████████╗
    ██╔═══██╗██╔══██╗██╔════╝████╗  ██║██║  ██║██╔═══██╗██║   ██║██╔════╝██╔════╝██╔════╝██║     ██╔══██╗╚══██╔══╝
    ██║   ██║██████╔╝█████╗  ██╔██╗ ██║███████║██║   ██║██║   ██║███████╗█████╗  █████╗  ██║     ███████║   ██║
    ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║██╔══██║██║   ██║██║   ██║╚════██║██╔══╝  ██╔══╝  ██║     ██╔══██║   ██║
    ╚██████╔╝██║     ███████╗██║ ╚████║██║  ██║╚██████╔╝╚██████╔╝███████║███████╗██║     ███████╗██║  ██║   ██║
     ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝╚═╝  ╚═╝ ╚═════╝  ╚═════╝ ╚══════╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝   ╚═╝
  
    */
    openHouseFlat(
      houseFlat: HouseFlat,
    ) {
      const modalRef = this.modalService.open(
        HouseFlatModalComponent
      );
  
      modalRef.componentInstance.houseFlat = houseFlat;
      modalRef.componentInstance.neighborhoodId = this.streetBuilding!.neighborhoodId;
      modalRef.componentInstance.streetBuildingId = this.streetBuilding!.streetBuildingId;
  
    }

}
