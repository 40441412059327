import { Component, Input } from '@angular/core';
import { Neighborhood } from '../../models/neighborhood.model';

@Component({
  selector: 'app-neighborhood-item',
  templateUrl: './neighborhood-item.component.html',
  styleUrl: './neighborhood-item.component.scss'
})
export class NeighborhoodItemComponent {

  @Input() neighborhood?: Neighborhood;

}
