import { Component, Input } from '@angular/core';
import { Charge } from '../../models/charge.model';
import moment from 'moment';

@Component({
  selector: 'app-charge-item',
  templateUrl: './charge-item.component.html',
  styleUrl: './charge-item.component.scss'
})
export class ChargeItemComponent {

  @Input() charge?: Charge;

  constructor() {

  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  async delete() {

    const month = moment(
      this.charge!.createdAt
    ).format('MMMM');

    const flag = confirm(`¿Esta seguro que desea borrar el cobro ${month}?`);

  }

}
