<div class="container-fluid py-3">
  <div class="row">

    <div class="col-12 mb-3 p-3 bg-white rounded d-flex">
      <button
        (click)="downloadReport()"
        class="btn btn-primary d-flex align-items-center justify-content-center me-2"
        type="button">
        <i class="material-icons me-2">download</i> Descargar reporte
      </button>

      @if (authService.currentUser?.profile === 'sudo') {
        <button
          (click)="generateUsers()"
          class="btn btn-outline-success d-flex align-items-center justify-content-center"
          type="button">
          <i class="material-icons me-2">refresh</i> Generar usuarios
        </button>
      }
    </div>

    @for (streetBuilding of streetBuildingService.streetsBuildings; track $index) {

      <app-street-building-item-2
        [streetBuilding]="streetBuilding">
      </app-street-building-item-2>

    }

    @if(streetBuildingService.loading) {
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    }

  </div>
</div>
