import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from './spinner.service';
import { ToastService } from './toast.service';
import { HouseFlat } from '../models/house-flat.model';
import { environment } from '../../environments/environment';
import { IServerResponse, ServerResponse } from '../models/server-response.model';
import { ChargeService } from './charge.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    public authService: AuthService,
    public chargeService: ChargeService,
    private http: HttpClient,
    public spinnerService: SpinnerService,
    public toastService: ToastService
  ) { 

  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
    ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async update(
    neighborhoodId: string,
    houseFlat: HouseFlat,
    data: any
  ): Promise<boolean> {

    let flag = false;

    const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/streets-buildings/${houseFlat.streetBuildingId}/houses-flats/${houseFlat.houseFlatId}/payments/${houseFlat.payment?.paymentId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Actualizando pago';

    try {

      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.put<IServerResponse>(
            urlApi,
            JSON.stringify(data),
            {
              headers: {
                "content-type": "application/json",
                'Authorization': token.toString()
              }
            }
          ).subscribe({
            next: (
                response: IServerResponse
              ) => resolve(
                new ServerResponse(response)
              ),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show(
                  {
                    header: 'Error de servidor',
                    body: `${error}`,
                    classname: 'bg-danger text-white'
                  }
                );
              }
            },
          });
        }
      );

      console.log(response);

      if(response.statusCode === 200) {
        flag = true;

        this.toastService.show(
          {
            body: `${response.message}`,
            classname: 'bg-success text-white'
          }
        );

        this.chargeService.getAll(
          neighborhoodId
        );

      } else {

        this.toastService.show(
          {
            header: 'Error de servidor',
            body: `${response.message}`,
            classname: 'bg-danger text-white'
          }
        );

      }

    } catch(error) {
      console.log(error);

      this.toastService.show(
        {
          header: 'Error',
          body: `${error}`,
          classname: 'bg-danger text-white'
        }
      );
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return flag;

  }
}
