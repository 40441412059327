<div class="modal-header">
  <div class="me-2">
    @if(visit?.service) {
      <img
        [src]="visit!.service?.image?.urlFile"
        [alt]="visit!.service?.service"
        style="width: calc(1.5vh + 1.5rem);">
    } @else {
      <img
        [src]="visit?.visitType?.image?.urlFile"
        [alt]="visit?.visitType?.description"
        style="width: calc(1.5vh + 1.5rem);">
    }
  </div>

  <div>
    <h4 class="modal-title">
      {{ visit?.houseFlat?.streetBuilding?.name }} {{ visit?.houseFlat?.number }}
    </h4>
    <small>
      @if(visit?.service) {
        {{ visit!.service?.service }} {{ visit?.fullName }}
      } @else {
        {{ visit?.fullName }}
      }
    </small>

  </div>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <div class="row">

      @for (proof of visit?.proofs; track $index) {

        <div class="col-12">
          <img
            [src]="proof.urlFile"
            [alt]="proof.fileId"
            class="w-100">
        </div>

      }

    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="col-12 my-3">
    <button
      (click)="activeModal.close()"
      type="button"
      class="btn btn-success w-100">
      OK
    </button>
  </div>
</div>