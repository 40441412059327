<div class="modal-header">

  <div>
    <h4 class="modal-title">
      {{ houseFlat?.payment?.createdAt | dateToMoment: 'MMMM YYYY' }}
    </h4>
    <small>
      {{ streetBuilding?.name }} {{ houseFlat?.number }}
    </small>

  </div>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  
</div>
<div class="modal-footer">
  <div class="col-12 my-3">
    <button
      (click)="activeModal.close()"
      type="button"
      class="btn btn-outline-danger w-100">
      Cancelar
    </button>
  </div>
  <div class="col-12">
    <button
      type="submit"
      form="formHouseFlat"
      class="btn btn-primary w-100">
      Actualizar
    </button>
  </div>
</div>