export interface IAnnouncementType {
  announcementType: string;
  description: string;
  uidCreatedBy?: string; // Opcional
  uidUpdatedBy?: string; // Opcional
  createdAt: string;
  updatedAt: string;
}

export class AnnouncementType {
  announcementType: string;
  description: string;
  uidCreatedBy?: string; // Opcional
  uidUpdatedBy?: string; // Opcional
  createdAt: Date;
  updatedAt: Date;

  constructor(data: IAnnouncementType) {
    this.announcementType = data.announcementType;
    this.description = data.description;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
  }
}
