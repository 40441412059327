// BankAccountType.model.ts

export interface IBankAccountType {
  bankAccountTypeId: string;
  bankAccountType: string;
  uidCreatedBy?: string; // Opcional
  uidUpdatedBy?: string; // Opcional
  createdAt: string;
  updatedAt: string;
}

export class BankAccountType {
  bankAccountTypeId: string;
  bankAccountType: string;
  uidCreatedBy?: string; // Opcional
  uidUpdatedBy?: string; // Opcional
  createdAt: Date;
  updatedAt: Date;

  constructor(data: IBankAccountType) {
    this.bankAccountTypeId = data.bankAccountTypeId;
    this.bankAccountType = data.bankAccountType;
    this.uidCreatedBy = data.uidCreatedBy;
    this.uidUpdatedBy = data.uidUpdatedBy;
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
  }
}
