<div class="container-fluid bg-primary-light py-2">
  <div class="row">
    <div class="col-6 d-flex align-items-center">

      <button
        class="btn pt-2 pb-0 d-block d-md-none"
        (click)="slideMenuService.showed = !slideMenuService.showed">
        <i class="material-icons">menu</i>
      </button>

      @if(title) {
        <div class="ps-2 ps-md-0">
          <b>{{ title }}</b>
        </div>
      }

    </div>

    <div class="col-6 text-end">

      <div ngbDropdown class="d-inline-block">
        <button type="button" class="btn" id="dropdownBasic1" ngbDropdownToggle>
          {{ authService.currentUser?.fullName }}
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          @if(neighborhoodService.neighborhoods.length > 1 || authService.currentUser?.profile === 'sudo') {
            <a 
              ngbDropdownItem 
              class="d-flex align-items-center"
              [routerLink]="['/dashboard/neighborhoods']">
              <i class="material-icons me-1">house</i>
              Colonias
            </a>
            <div class="dropdown-divider"></div>
          }
          <button 
            ngbDropdownItem 
            class="d-flex align-items-center text-danger"
            (click)="authService.signOut()">
            <i class="material-icons me-1">logout</i>
            Cerrar sesión
          </button>
        </div>
      </div>

    </div>
  </div>
</div>