// AppUser.model.ts

export interface IAppUser {
  uid: string;
  profile: string;
  names: string;
  paternalLastName: string;
  maternalLastName: string;
  username: string;
  email: string;
  password?: string;
  temporaryPassword: boolean;
  lastLoginAt: string;
  createdAt: string;
  updatedAt: string;
}

export class AppUser {
  uid: string;
  profile: string;
  names: string;
  paternalLastName: string;
  maternalLastName: string;
  username: string;
  email: string;
  password?: string;
  temporaryPassword: boolean;
  lastLoginAt: Date;
  createdAt: Date;
  updatedAt: Date;

  constructor(data: IAppUser) {
    this.profile = data.profile;
    this.names = data.names;
    this.paternalLastName = data.paternalLastName;
    this.maternalLastName = data.maternalLastName;
    this.uid = data.uid;
    this.username = data.username;
    this.email = data.email;
    this.password = data.password;
    this.temporaryPassword = data.temporaryPassword;
    this.lastLoginAt = new Date(data.lastLoginAt);
    this.createdAt = new Date(data.createdAt);
    this.updatedAt = new Date(data.updatedAt);
  }

  get fullName(): string {

    let fullName = '';

    if(this.names != '') {
      fullName = `${this.names} ${this.paternalLastName} ${this.maternalLastName}`;
    } else {
      fullName = this.username;
    }

    return fullName;
  }

}
