<div class="container-fluid py-3">
  <div class="row">

    <div class="col-12">

      <p-table #dt
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [filterDelay]="0"
          [loading]="bankAccountTypeService.loading"
          [paginator]="true"
          [rows]="10"
          [rowHover]="true"
          [rowsPerPageOptions]="[10,25,50]"
          [showCurrentPageReport]="true" 
          [value]="bankAccountTypeService.bankAccountTypes"
          pDroppable="row">
          <ng-template pTemplate="caption">
            <div class="container-fluid">
              <div class="row">
                <div class="col-4 d-flex align-items-center">
                  Tipos de cuentas bancarias
                </div>

                <div class="col-8 text-end">
                  <button
                    (click)="open()"
                    class="btn btn-success rounded-circle me-2">
                    <i class="material-icons mt-1">add</i>
                  </button>

                  <button
                    (click)="bankAccountTypeService.getAll()"
                    class="btn btn-primary rounded-circle me-2">
                    <i class="material-icons mt-1">refresh</i>
                  </button>

                  <button
                    class="btn btn-light rounded-circle me-2">
                    <i class="material-icons mt-1">filter_alt_off</i>
                  </button>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
              <tr>

                <th pSortableColumn="bankAccountTypeId">
                  ID
                  <p-sortIcon field="bankAccountTypeId"></p-sortIcon>
                  <p-columnFilter type="text" field="bankAccountTypeId" display="menu"></p-columnFilter>
                </th>

                <th pSortableColumn="bankAccountType">
                  Nombre
                  <p-sortIcon field="bankAccountType"></p-sortIcon>
                  <p-columnFilter type="text" field="bankAccountType" display="menu"></p-columnFilter>
                </th>

                <th></th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-bankAccountType let-rowIndex="rowIndex">
              <tr class="p-selectable-row">

                <td>
                  <span class="p-column-title">ID</span>
                  {{bankAccountType.bankAccountTypeId}}
                </td>

                <td>
                  <span class="p-column-title">Nombre</span>
                  {{bankAccountType.bankAccountType}}
                </td>

                <td class="text-end">

                  <button
                    (click)="open(bankAccountType)"
                    class="btn btn-sm btn-light rounded-circle me-2 my-1">
                    <i class="material-icons mt-1">edit</i>
                  </button>

                  <button
                    class="btn btn-sm btn-danger rounded-circle me-2 my-1">
                    <i class="material-icons mt-1">delete</i>
                  </button>
                </td>
              </tr>
          </ng-template>
      </p-table>

    </div>

  </div>
</div>