import { Component, Input } from '@angular/core';
import { BankAccount } from '../../models/bank-account.model';
import { UtilService } from '../../services/util.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BankAccountService } from '../../services/bank-account.service';
import { BankAccountModalComponent } from '../bank-account-modal/bank-account-modal.component';

@Component({
  selector: 'app-bank-account-item',
  templateUrl: './bank-account-item.component.html',
  styleUrl: './bank-account-item.component.scss'
})
export class BankAccountItemComponent {

  @Input() bankAccount?: BankAccount;

  constructor(
    public utilService: UtilService,
    public bankAccountService: BankAccountService,
    private modalService: NgbModal,
  ) { }

  /*
    ██████╗ ███████╗██╗     ███████╗████████╗███████╗
    ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
    ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
    ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
    ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
    ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝
  
    */
    async delete() {
  
      const flag = confirm(
        `¿Esta seguro que desea borrar el numero de cuenta de ${this.bankAccount!.bankName}?`
      );
  
      if(flag) {
        await this.bankAccountService.delete(
          this.bankAccount!
        );
      }
  
    }
  
    /*
     ██████╗ ██████╗ ███████╗███╗   ██╗
    ██╔═══██╗██╔══██╗██╔════╝████╗  ██║
    ██║   ██║██████╔╝█████╗  ██╔██╗ ██║
    ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║
    ╚██████╔╝██║     ███████╗██║ ╚████║
      ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝
  
    */
    open() {
      const modalRef = this.modalService.open(
        BankAccountModalComponent
      );
  
      modalRef.componentInstance.bankAccount = this.bankAccount;
      modalRef.componentInstance.neighborhoodId = this.bankAccount?.neighborhoodId;
  
    }

}
