import { Component } from '@angular/core';

@Component({
  selector: 'app-announcement-modal',
  templateUrl: './announcement-modal.component.html',
  styleUrl: './announcement-modal.component.scss'
})
export class AnnouncementModalComponent {

}
