import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SessionLayoutComponent } from './layouts/session-layout/session-layout.component';
import { LogInPageComponent } from './pages/log-in-page/log-in-page.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { DashboardPageComponent } from './pages/dashboard/dashboard-page/dashboard-page.component';
import { NeighborhoodsPageComponent } from './pages/dashboard/neighborhoods-page/neighborhoods-page.component';
import { NeighborhoodTypesPageComponent } from './pages/dashboard/neighborhood-types-page/neighborhood-types-page.component';
import { ProofTypesPageComponent } from './pages/dashboard/proof-types-page/proof-types-page.component';
import { VisitTypesPageComponent } from './pages/dashboard/visit-types-page/visit-types-page.component';
import { ModulesPageComponent } from './pages/dashboard/security/modules-page/modules-page.component';
import { ProfilesPageComponent } from './pages/dashboard/security/profiles-page/profiles-page.component';
import { UsersPageComponent } from './pages/dashboard/security/users-page/users-page.component';
import { NeighborhoodLayoutComponent } from './layouts/neighborhood-layout/neighborhood-layout.component';
import { NeighborhoodPageComponent } from './pages/neighborhood/neighborhood-page/neighborhood-page.component';
import { SettlersPageComponent } from './pages/neighborhood/settlers-page/settlers-page.component';
import { StreetsBuildingPageComponent } from './pages/neighborhood/streets-building-page/streets-building-page.component';
import { HousesFlatsPageComponent } from './pages/neighborhood/houses-flats-page/houses-flats-page.component';
import { VisitsPageComponent } from './pages/neighborhood/visits-page/visits-page.component';
import { SettingsModulesPageComponent } from './pages/neighborhood/settings/settings-modules-page/settings-modules-page.component';
import { SettingsProfilesPageComponent } from './pages/neighborhood/settings/settings-profiles-page/settings-profiles-page.component';
import { GeneralPageComponent } from './pages/neighborhood/settings/general-page/general-page.component';
import { NotificationTypesPageComponent } from './pages/dashboard/catalogues/notification-types-page/notification-types-page.component';
import { ServicesPageComponent } from './pages/neighborhood/settings/services-page/services-page.component';
import { AnnouncementsPageComponent } from './pages/neighborhood/announcements-page/announcements-page.component';
import { GuardsPageComponent } from './pages/dashboard/guards-page/guards-page.component';
import { EmergencyNumbersPageComponent } from './pages/neighborhood/emergency-numbers-page/emergency-numbers-page.component';
import { PaymentsPageComponent } from './pages/neighborhood/payments-page/payments-page.component';
import { BankAccountTypesPageComponent } from './pages/dashboard/bank-account-types-page/bank-account-types-page.component';
import { BankAccountsPageComponent } from './pages/dashboard/bank-accounts-page/bank-accounts-page.component';
import { PaymentPageComponent } from './pages/neighborhood/payment-page/payment-page.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '',
    component: SessionLayoutComponent,
    children: [
      {
        path: 'login',
        component: LogInPageComponent
      }
    ]
  },
  {
    path: 'dashboard',
    component: DashboardLayoutComponent,
    children: [
      {
        path: '',
        component: DashboardPageComponent
      },
      {
        path: 'neighborhoods',
        component: NeighborhoodsPageComponent
      },
      {
        path: 'catalogues',
        children: [
          {
            path: 'neighborhood-types',
            component: NeighborhoodTypesPageComponent
          },
          {
            path: 'bank-account-types',
            component: BankAccountTypesPageComponent
          },
          {
            path: 'notification-types',
            component: NotificationTypesPageComponent
          },
          {
            path: 'proof-types',
            component: ProofTypesPageComponent
          },
          {
            path: 'visit-types',
            component: VisitTypesPageComponent
          }
        ]
      },
      {
        path: 'security',
        children: [
          {
            path: 'modules',
            component: ModulesPageComponent
          },
          {
            path: 'profiles',
            component: ProfilesPageComponent
          },
          {
            path: 'users',
            component: UsersPageComponent
          }
        ]
      }
    ]
  },
  {
    path: 'neighborhoods',
    component: NeighborhoodLayoutComponent,
    children: [
      {
        path: ':neighborhoodId',
        children: [
          {
            path: '',
            component: NeighborhoodPageComponent
          },
          {
            path: 'guards',
            component: GuardsPageComponent
          },
          {
            path: 'payments',
            children: [
              {
                path: '',
                component: PaymentsPageComponent
              },
              {
                path: ':paymentId',
                component: PaymentPageComponent
              }
            ]
          },
          {
            path: 'settlers',
            component: SettlersPageComponent
          },
          {
            path: 'streets-buildings',
            component: StreetsBuildingPageComponent
          },
          {
            path: 'houses-flats',
            component: HousesFlatsPageComponent
          },
          {
            path: 'visits',
            component: VisitsPageComponent
          },
          {
            path: 'announcements',
            component: AnnouncementsPageComponent
          },
          {
            path: 'settings',
            children: [

              {
                path: 'modules',
                component: SettingsModulesPageComponent
              },
              {
                path: 'profiles',
                component: SettingsProfilesPageComponent
              },
              {
                path: 'bank-accounts',
                component: BankAccountsPageComponent
              },
              {
                path: 'emergency-numbers',
                component: EmergencyNumbersPageComponent
              },
              {
                path: 'services',
                component: ServicesPageComponent
              },
              // {
              //   path: 'proof-types',
              //   component: SettingsProofTypesPageComponent
              // }
              {
                path: 'general',
                component: GeneralPageComponent
              }

            ]
          }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
