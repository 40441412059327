import { Injectable } from '@angular/core';
import { IService, Service } from '../models/service.model';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { SpinnerService } from './spinner.service';
import { ToastService } from './toast.service';
import { environment } from '../../environments/environment';
import { IServerResponse, ServerResponse } from '../models/server-response.model';

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  public loading: boolean = false;
  public services: Service[] = [];

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    public spinnerService: SpinnerService,
    public toastService: ToastService
  ) { }

  /*
   ██████╗██████╗ ███████╗ █████╗ ████████╗███████╗
  ██╔════╝██╔══██╗██╔════╝██╔══██╗╚══██╔══╝██╔════╝
  ██║     ██████╔╝█████╗  ███████║   ██║   █████╗
  ██║     ██╔══██╗██╔══╝  ██╔══██║   ██║   ██╔══╝
  ╚██████╗██║  ██║███████╗██║  ██║   ██║   ███████╗
   ╚═════╝╚═╝  ╚═╝╚══════╝╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async create(
    neighborhoodId: string,
    service: IService
  ): Promise<boolean> {
    let flag = false;
    const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/services`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Registrando servicio...';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.post<IServerResponse>(
            urlApi,
            JSON.stringify(service),
            {
              headers: {
                'content-type': 'application/json',
                'Authorization': token.toString()
              }
            }
          ).subscribe({
            next: (response: IServerResponse) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show({
                  header: 'Error de servidor',
                  body: `${error}`,
                  classname: 'bg-danger text-white'
                });
              }
            },
          });
        }
      );

      if(response.statusCode === 201) {
        flag = true;
        this.toastService.show({
          body: `${response.message}`,
          classname: 'bg-success text-white'
        });
        this.getAll(
          neighborhoodId
        );
      } else {
        this.toastService.show({
          header: 'Error de servidor',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }
    } catch(error) {
      this.toastService.show({
        header: 'Error',
        body: `${error}`,
        classname: 'bg-danger text-white'
      });
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return flag;
  }

  /*
  ██████╗ ███████╗██╗     ███████╗████████╗███████╗
  ██╔══██╗██╔════╝██║     ██╔════╝╚══██╔══╝██╔════╝
  ██║  ██║█████╗  ██║     █████╗     ██║   █████╗
  ██║  ██║██╔══╝  ██║     ██╔══╝     ██║   ██╔══╝
  ██████╔╝███████╗███████╗███████╗   ██║   ███████╗
  ╚═════╝ ╚══════╝╚══════╝╚══════╝   ╚═╝   ╚══════╝

  */
  async delete(
    service: Service
  ): Promise<boolean> {
    let flag = false;
    const urlApi = `${environment.urlApi}/neighborhoods/${service.neighborhoodId}/services/${service.serviceId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Eliminando servicio...';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.delete<IServerResponse>(
            urlApi,
            {
              headers: { 'Authorization': token.toString() }
            }
          ).subscribe({
            next: (response: IServerResponse) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show({
                  header: 'Error de servidor',
                  body: `${error}`,
                  classname: 'bg-danger text-white'
                });
              }
            },
          });
        }
      );

      if(response.statusCode === 200) {
        flag = true;
        this.toastService.show({
          body: `${response.message}`,
          classname: 'bg-success text-white'
        });
        this.getAll(
          service.neighborhoodId
        );
      } else {
        this.toastService.show({
          header: 'Error de servidor',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }
    } catch(error) {
      this.toastService.show({
        header: 'Error',
        body: `${error}`,
        classname: 'bg-danger text-white'
      });
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';
    return flag;
  }

  /*
   ██████╗ ███████╗████████╗ █████╗ ██╗     ██╗
  ██╔════╝ ██╔════╝╚══██╔══╝██╔══██╗██║     ██║
  ██║  ███╗█████╗     ██║   ███████║██║     ██║
  ██║   ██║██╔══╝     ██║   ██╔══██║██║     ██║
  ╚██████╔╝███████╗   ██║   ██║  ██║███████╗███████╗
   ╚═════╝ ╚══════╝   ╚═╝   ╚═╝  ╚═╝╚══════╝╚══════╝

  */
  async getAll(
    neighborhoodId: string
  ) {
    this.loading = true;
    const urlApi = `${environment.urlApi}/neighborhoods/${neighborhoodId}/services`;

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse<{ services: IService[] }>>(
        (resolve, reject) => {
          this.http.get<IServerResponse<{ services: IService[] }>>(
            urlApi,
            {
              headers: {
                "content-type": "application/json",
                "Authorization": token.toString()
              }
            }
          ).subscribe({
            next: (response) => resolve(new ServerResponse(response)),
            error: (error) => reject(error),
          });
        }
      );

      if (response.statusCode === 200) {
        this.services = response.data!.services.map(
          srv => new Service(srv)
        );
      }
    } catch(error) {
      this.toastService.show({
        header: 'Error de servidor',
        body: `${error}`,
        classname: 'bg-danger text-white'
      });
    }

    this.loading = false;
  }

  /*
  ██╗   ██╗██████╗ ██████╗  █████╗ ████████╗███████╗
  ██║   ██║██╔══██╗██╔══██╗██╔══██╗╚══██╔══╝██╔════╝
  ██║   ██║██████╔╝██║  ██║███████║   ██║   █████╗
  ██║   ██║██╔═══╝ ██║  ██║██╔══██║   ██║   ██╔══╝
  ╚██████╔╝██║     ██████╔╝██║  ██║   ██║   ███████╗
   ╚═════╝ ╚═╝     ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝

  */
  async update(
    service: IService
  ): Promise<boolean> {
    let flag = false;
    const urlApi = `${environment.urlApi}/neighborhoods/${service.neighborhoodId}/services/${service.serviceId}`;

    this.spinnerService.loading = true;
    this.spinnerService.message = 'Actualizando servicio...';

    try {
      const token = await this.authService.getToken();

      const response = await new Promise<ServerResponse>(
        (resolve, reject) => {
          this.http.put<IServerResponse>(
            urlApi,
            JSON.stringify(service),
            {
              headers: {
                'content-type': 'application/json',
                'Authorization': token.toString()
              }
            }
          ).subscribe({
            next: (response: IServerResponse) => resolve(new ServerResponse(response)),
            error: (error) => {
              if (error.error) {
                resolve(new ServerResponse(error.error));
              } else {
                reject(error);
                this.toastService.show({
                  header: 'Error de servidor',
                  body: `${error}`,
                  classname: 'bg-danger text-white'
                });
              }
            },
          });
        }
      );

      if (response.statusCode === 200) {
        flag = true;
        this.toastService.show({
          body: `${response.message}`,
          classname: 'bg-success text-white'
        });
        this.getAll(
          service.neighborhoodId
        );
      } else {
        this.toastService.show({
          header: 'Error de servidor',
          body: `${response.message}`,
          classname: 'bg-danger text-white'
        });
      }
    } catch (error) {
      this.toastService.show({
        header: 'Error',
        body: `${error}`,
        classname: 'bg-danger text-white'
      });
    }

    this.spinnerService.loading = false;
    this.spinnerService.message = '';

    return flag;
  }
}
