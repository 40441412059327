import { Component, Input } from '@angular/core';
import { Visit } from '../../models/visit.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-visit-modal',
  templateUrl: './visit-modal.component.html',
  styleUrl: './visit-modal.component.scss'
})
export class VisitModalComponent {

  @Input() visit?: Visit;

  constructor(
      public activeModal: NgbActiveModal
    ) {}

}
