import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StreetBuildingService } from '../../../services/street-building.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BankAccountService } from '../../../services/bank-account.service';
import { BankAccount } from '../../../models/bank-account.model';
import { BankAccountModalComponent } from '../../../components/bank-account-modal/bank-account-modal.component';

@Component({
  selector: 'app-bank-accounts-page',
  templateUrl: './bank-accounts-page.component.html',
  styleUrl: './bank-accounts-page.component.scss'
})
export class BankAccountsPageComponent {

  public neighborhoodId: string = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    public bankAccountService: BankAccountService,
    private modalService: NgbModal,
  ) {

    this.activatedRoute.params.subscribe(
      async params => {

        this.neighborhoodId = params['neighborhoodId'];          

        await this.bankAccountService.getAll(
          this.neighborhoodId!
        );
      }
    );

  }


  /*
   ██████╗ ██████╗ ███████╗███╗   ██╗
  ██╔═══██╗██╔══██╗██╔════╝████╗  ██║
  ██║   ██║██████╔╝█████╗  ██╔██╗ ██║
  ██║   ██║██╔═══╝ ██╔══╝  ██║╚██╗██║
  ╚██████╔╝██║     ███████╗██║ ╚████║
    ╚═════╝ ╚═╝     ╚══════╝╚═╝  ╚═══╝

  */
  open() {
    const modalRef = this.modalService.open(
      BankAccountModalComponent
    );

    modalRef.componentInstance.neighborhoodId = this.neighborhoodId;

  }

}
